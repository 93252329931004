/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
// imagenes azules
import treseme from '../../img/FARMA2/3M.png';
import astra from '../../img/FARMA2/ASTRAZENECA.png';
import abott from '../../img/FARMA2/ABBOTT.png';
import alcon from '../../img/FARMA2/ALCON.png';
import almirall from '../../img/FARMA2/ALKA SELTZER.png';
import ashonplafa from '../../img/FARMA2/ASHONPLAFA.png';
import aspen from '../../img/FARMA2/ASPEN.png';
import asopharma from '../../img/FARMA2/ASOFARMA.png';
import astamedica from '../../img/FARMA2/ASTA MEDICA.png';
import betafarm from '../../img/FARMA2/ASOFARMA.png';
import bago from '../../img/FARMA2/BAGÓ.png';
import BOEHRINGER from '../../img/FARMA2/BOEHRINGER.png';
import bayer from '../../img/FARMA2/BAYER.png';
import GROSSMAN from '../../img/FARMA2/GROSSMAN.png';
import INFARMA from '../../img/FARMA2/INFARMA.png';
import LASANTE from '../../img/FARMA2/LA SANTÉ.png';
import bayerhealth from '../../img/FARMA2/BAYER HEALTH CARE.png';
import BIAL from '../../img/FARMA2/BIAL.png';
import biokemical from '../../img/FARMA2/BIOKEMICAL.png';
import biopas from '../../img/FARMA2/BIOPAS.png';
import bonima from '../../img/FARMA2/BIOPAS.png';
import bussie from '../../img/FARMA2/BUSSIÉ.png';
import cheplafarm from '../../img/FARMA2/CHEPLAPHARM.png';
import church from '../../img/FARMA2/CHEPLAPHARM.png';
import clement from '../../img/FARMA2/CLEMENT CLARKE INT..png';
import MEDTRONIC from '../../img/FARMA2/MEDTRONIC.png';
import DETECTOR from '../../img/FARMA2/DETECTOR.png';
import drifarma from '../../img/FARMA2/DETECTOR.png';
import EUROMED from '../../img/FARMA2/EUROMED.png';
import EUROTEST from '../../img/FARMA2/EUROTEST.png';
import farmitalia from '../../img/FARMA2/EUROTEST.png';
import FLEET from '../../img/FARMA2/FLEET.png';
// import GALDERMA from '../../img/FARMA2/GALDERMA.png';
import gnoma from '../../img/FARMA2/GENOMALAB.png';
import GENZYME from '../../img/FARMA2/GENZYME.png';
import GRIFOLS from '../../img/FARMA2/GRIFOLS.png';
import GRUNENTHAL from '../../img/FARMA2/GRUNENTHAL.png';
import grupofarma from '../../img/FARMA2/GRUPO PHARMA.png';
import gsk from '../../img/FARMA2/GSK.png';
import hoeschst from '../../img/FARMA2/GSK.png';
//import jpharma from '../../img/FARMA2/J&J PHARMA.png';
import jpharma from '../../img/J&J.jpg';
import lafrancol from '../../img/FARMA2/LA SANTÉ.png';
import lasante from '../../img/FARMA2/LA SANTÉ.png';
import lederle from '../../img/FARMA2/LA SANTÉ.png';
import lilly from '../../img/FARMA2/LILLY.png';
import leo from '../../img/FARMA2/LEO.png';
import lundbeck from '../../img/FARMA2/Lundbeck-logo.png';
import md from '../../img/FARMA2/MEADJOHNSON.png';
import MEAD from '../../img/FARMA2/MEADJOHNSON.png';
import MEDICE from '../../img/FARMA2/MEADJOHNSON.png';
import medix from '../../img/FARMA2/MEDIX.png';
import MK from '../../img/FARMA2/MK.png';
import ORGANON from '../../img/FARMA2/ORGANON.png';
import PG from '../../img/FARMA2/P&G HEALTH.png';
import mepha from '../../img/FARMA2/MEPHA.png';
import MERCK from '../../img/FARMA2/MERCK.png';
import MICROLIFE from '../../img/FARMA2/MEADJOHNSON.png';
import msd from '../../img/FARMA2/MSD.png';
import nestlecaribean from '../../img/FARMA2/NESTLE CARRIBEAN.png';
import novartis from '../../img/FARMA2/NOVARTIS.png';
import NUTRICIA from '../../img/FARMA2/NUTRICIA.png';
import om from '../../img/FARMA2/OM.png';
import owen from '../../img/FARMA2/OWEN MUMFORD.png';
import panalab from '../../img/FARMA2/PANALAB.png';
import parkedavis from '../../img/FARMA2/PANALAB.png';
import pasmo from '../../img/FARMA2/PASMO.png';
import pfizer from '../../img/FARMA2/PFIZER.png';
import PHARMACIA from '../../img/FARMA2/UPJOHN.png';
import pharmanova from '../../img/FARMA2/PANALAB.png';
import procaps from '../../img/FARMA2/PROCAPS.png';
import proderfarma from '../../img/FARMA2/PRODES.png';
import PROMECO from '../../img/FARMA2/PROCAPS.png';
import QC from '../../img/FARMA2/QC.png';
import rhone from '../../img/FARMA2/PROCAPS.png';
import roche from '../../img/FARMA2/ROCHE.png';
import roemmers from '../../img/FARMA2/ROEMMERS.png';
import roussel from '../../img/FARMA2/PROCAPS.png';
import rowe from '../../img/FARMA2/ROWE.png';
import sandoz from '../../img/FARMA2/SANDOZ.png';
import sanofis from '../../img/FARMA2/SANOFI.png';
import searle from '../../img/FARMA2/TECNOQUIMICAS.png';
import servier from '../../img/FARMA2/SERVIER.png';
import schering from '../../img/FARMA2/SCHERING PLOUGH.png';
import stiefel from '../../img/FARMA2/SWISSFARM.png';
import superior from '../../img/FARMA2/SUPERIOR.png';
import SWISSFARM from '../../img/FARMA2/SWISSFARM.png';
import talecris from '../../img/FARMA2/SWISSFARM.png';
import ucb from '../../img/FARMA2/SWISSFARM.png';
import UNIPHARM from '../../img/FARMA2/UNIPHARM.png';
import valeant from '../../img/FARMA2/GROSSMAN.png';
import VIFOR from '../../img/FARMA2/VIFOR PHARMA.png';
import VITOTAL from '../../img/FARMA2/VITOTAL.png';
import fungiplus from '../../img/FARMA2/Fungiplus.png';
import steriscrub from '../../img/FARMA2/Steriscrub.png';
import tecnoquimicas from '../../img/FARMA2/TECNOQUIMICAS.png';
import Wye from '../../img/FARMA2/WYETH.png';


// imagenes para modal
import eme from '../../img/farma/3M.png';
import logoAstra from '../../img/farma/ASTRAZENECA.png';
import abottNormal from '../../img/farma/ABBOTT.png';
import logoBetafarm from '../../img/farma/Betafarm.png';
import logoAlcon from '../../img/farma/ALCON.png';
import logoBago from '../../img/farma/BAGÓ.png';
import logoAlmiral from '../../img/farma/ALKA SELTZER.png';
import logoBayer from '../../img/farma/BAYER.png';
import logoBussie from '../../img/farma/BUSSIÉ.png';
import logoGnomma from '../../img/farma/GENOMALAB.png';
import logoGsk from '../../img/farma/GSK.png';
import logoLafrancol from '../../img/farma/LAFRANCOL.png';
import logolasante from '../../img/farma/LA SANTÉ.png';
import logoLilly from '../../img/farma/LILLY.png';
import logoMexix from '../../img/farma/MEDIX.png';
import logoMsd from '../../img/farma/MSD.png';
import logoNovartis from '../../img/farma/NOVARTIS.png';
import logoPfizer from '../../img/farma/PFIZER.png';
import logoPharma from '../../img/farma/PHARMACIA & UPJOHN.png';
import logoRoche from '../../img/farma/ROCHE.png';
import logoRoemmers from '../../img/farma/ROEMMERS.png';
import logoSandoz from '../../img/farma/SANDOZ.png';
import logoSanofi from '../../img/farma/SANOFI.png';
import logoSchering from '../../img/farma/SHERING.png';
import logoStiefel from '../../img/farma/STIEFEL.png';
import logoTalecris from '../../img/farma/TALECRIS.png';
import logoUnipharm from '../../img/farma/UNIPHARM.png';
import logoAspen from '../../img/farma/ASPEN.png';
import logoBial from '../../img/farma/BIAL.png';
import logoMEDTRONIC from '../../img/farma/MEDTRONIC.png';
import logoDetector from '../../img/farma/DETECTOR.png';
import logoEuromed from '../../img/farma/EUROMED.png';
import logoEurotest from '../../img/farma/EUROTEST.png';
import logoFleet from '../../img/farma/FLEET.png';
// import logoGalderma from '../../img/farma/GALDERMA.png';
import logoGenzyme from '../../img/farma/GENZYME.png';
import logoGrifols from '../../img/farma/GRIFOLS.png';
import logoGrunenthal from '../../img/farma/GRUNENTHAL.png';
import logoMead from '../../img/farma/MEADJOHNSON.png';
import logoMedice from '../../img/farma/MEDICE.png';
import logoMerck from '../../img/farma/MERCK.png';
import logoMicrolife from '../../img/farma/MICROLIFE.png';
import logoNutricia from '../../img/farma/NUTRICIA.png';
import logoPromeco from '../../img/farma/PROMECO.png';
import logoQC from '../../img/farma/QC.png';
import logoSwissfarm from '../../img/farma/SWISSFARM.png';
import logoVifor from '../../img/farma/VIFOR PHARMA.png';
import logoVitotal from '../../img/farma/VITOTAL.png';
import logoFungiplus from '../../img/farma/Fungiplus.png';

import logoAshonplafa from '../../img/farma/ASHONPLAFA.png';
import logoAstamedica from '../../img/farma/ASTA MEDICA.png';
import logoBayerHealth from '../../img/farma/BAYER HEALTH CARE.png';
import logoBiokemical from '../../img/farma/BIOKEMICAL.png';
import logoBiopas from '../../img/farma/BIOPAS.png';
import logoBonima from '../../img/farma/BONIMA MK.png';
import logoCheplapharm from '../../img/farma/CHEPLAPHARM.png';
import logoChurch from '../../img/farma/CHURCH.png';
import logoClement from '../../img/farma/CLEMENT CLARKE INT..png';
import logoDrifarma from '../../img/farma/DRIFARMA.png';
import logofarmitalia from '../../img/farma/FARMITALIA.png';
import logogrupofarma from '../../img/farma/GRUPO PHARMA.png';
import logohoest from '../../img/farma/HOESCHST.png';
import logojpharma from '../../img/J&J.jpg';
import logolederle from '../../img/farma/LEDERLE.png';
import logoleo from '../../img/farma/LEO.png';
import logolundbeck from '../../img/farma/LUNDBECK.png';
import logomd from '../../img/farma/M&D PHARMA COSTA RIC.png';
import logomepha from '../../img/farma/MEPHA.png';
import logonestlecaribean from '../../img/farma/NESTLE CARRIBEAN.png';
import logoom from '../../img/farma/OM.png';
import logoowen from '../../img/farma/OWEN MUMFORD.png';
import logopanalab from '../../img/farma/PANALAB.png';
import logoparkedavis from '../../img/farma/PARKE DAVIS.png';
import logopasmo from '../../img/farma/PASMO.png';
import logopharmanova from '../../img/farma/PHARMANOVA.png';
import logoprocaps from '../../img/farma/PROCAPS.png';
import logoproderfarma from '../../img/farma/PRODER PHARMA.png';
import logorhone from '../../img/farma/RHONE POULENC RORER.png';
import logoroussel from '../../img/farma/ROUSSEL.png';
import logorowe from '../../img/farma/ROWE.png';
import logosearle from '../../img/farma/SEARLE.png';
import logoservier from '../../img/farma/SERVIER.png';
import logosuperior from '../../img/farma/SUPERIOR.png';
import logoucb from '../../img/farma/UCB.png';
import logovaleant from '../../img/farma/VALEANT GROSSMAN.png';
import logoSteriscrub from '../../img/farma/Steriscrub.png';
import logoAsopharma from '../../img/farma/ASOFARMA.png';
import logoBoeh from '../../img/farma/BOEHRINGER.png';
import logoGrossman from '../../img/farma/GROSSMAN.png';
import logoInfarma from '../../img/farma/INFARMA.png';
import logoTecnoquimicas from '../../img/farma/TECNOQUIMICAS.png';
import logoProdes from '../../img/farma/PRODES.png';
import logoPG from '../../img/farma/P&G HEALTH.png';
import logoOrganon from '../../img/farma/ORGANON.png';
import logoMK from '../../img/farma/MK.png';
// import JohnsonLogo from '../../img/Johnson&Johnson.png';
//import logoTecnoquimicas from '../../img/farma/TECNOQUIMICAS.png';
class Farma extends React.Component {
  constructor(){
    super();

    this.state = {
         eme: false,
         abott: false,
         alcon: false,
         almiral: false,
         aspen: false,
         aventis: false,
         astra: false,
         bago: false,
         bayer: false,
         bial: false,
         bussie: false,
         covidien: false,
         detector: false,
         euromed: false,
         eurotest: false,
         fleet: false,
         galderma: false,
         gnoma: false,
         genzyme: false,
         grifols: false,
         grunenthal: false,
         gsk: false,
         lafrancol: false,
         lasante: false,
         lilly: false,
         mead: false,
         medice: false,
         medix: false,
         merck: false,
         microlife: false,
         msd: false,
         novartis: false,
         nutricia: false,
         pfizer: false,
         pharma: false,
         promeco: false,
         qc: false,
         roche: false,
         roemmers: false,
         sandoz: false,
         sanofis: false,
         schering: false,
         stiefel: false,
         talecris: false,
         swissfarm :false,
         unipharm: false,
         vifor: false,
         vitotal: false,
         wyeth: false,
         ashonplafa : false,
         astamedica : false,
         bayerhealth : false,
         biokemical : false,
         biopas : false,
         bonima : false,
         cheplapharm : false,
         church : false,
         clement : false,
         drifarma : false,
         farmitalia : false,
         grupopharma : false,
         hoeschst : false,
         jpharma : false,
         lederle : false,
         leo : false,
         lundbeck : false,
         md : false,
         mepha : false,
         nestlecarribean : false,
         om : false,
         owen : false,
         panalab : false,
         parkedavis : false,
         pasmo : false,
         pharmanova : false,
         procaps : false,
         proderpharma : false,
         rhone : false,
         roussel : false,
         rowe : false,
         searle : false,
         servier : false,
         superior : false,
         ucb : false,
         valeant: false,
         steriscrub: false,
    }
}
//eme
changeColor(){
   this.setState({eme: !this.state.eme})
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
   this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
   this.setState({steriscrub : false })
}
//abot
  changeColor2(){
     this.setState({eme: false})
   this.setState({abott: !this.state.abott})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})   
   this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
   this.setState({steriscrub : false })
    }
//alcon
changeColor3(){
  this.setState({eme: false})
   this.setState({abott: false})
   this.setState({alcon: !this.state.alcon})
   this.setState({almiral: false})
   this.setState({steriscrub : false })
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//almirall
changeColor4(){
  this.setState({eme: false})
   this.setState({abott: false})
   this.setState({steriscrub : false })
   this.setState({alcon: false})
   this.setState({almiral: !this.state.almiral})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
// aspen
changeColor5(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: !this.state.aspen})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//aventis
changeColor6(){
this.setState({eme: false})
   this.setState({abott: false})
   this.setState({steriscrub : false })
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: !this.state.aventis})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//aztra
changeColor7(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: !this.state.astra})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//bago
changeColor8(){
 this.setState({eme: false})
 this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: !this.state.bago})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//bayer
changeColor9(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: !this.state.bayer})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//bial
changeColor10(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: !this.state.bial})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//bussie
changeColor11(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: !this.state.bussie})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//covidien
changeColor12(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: !this.state.covidien})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//detector
changeColor13(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: !this.state.detector})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})

           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//euromed
changeColor14(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: !this.state.euromed})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//eurotest
changeColor15(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: !this.state.eurotest})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//fleet
changeColor16(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: !this.state.fleet})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//galderma
changeColor17(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: !this.state.galderma})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//gnoma
changeColor18(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: !this.state.gnoma})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//gensyme
changeColor19(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: !this.state.genzyme})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//grifols
changeColor20(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: !this.state.grifols})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false}) 
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//grunenthal
changeColor21(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: !this.state.grunenthal})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//gsk
changeColor22(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: !this.state.gsk})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//lasante
changeColor23(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: !this.state.lafrancol})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false}) 
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
    }

//lafrancol
changeColor24(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: !this.state.lasante})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//lilly
changeColor25(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: !this.state.lilly})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//mead
changeColor26(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: !this.state.mead})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//medice
changeColor27(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: !this.state.medice})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false}) 
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
      }
//medix
changeColor28(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: !this.state.medix})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//merck
changeColor29(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: !this.state.merck})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//microlife
changeColor30(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: !this.state.microlife})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//msd
changeColor31(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: !this.state.msd})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//novartis
changeColor32(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: !this.state.novartis})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//nutricia
changeColor33(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: !this.state.nutricia})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//pfizer
changeColor34(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: !this.state.pfizer})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//pharma
changeColor35(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: !this.state.pharma})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//promeco
changeColor36(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: !this.state.promeco})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

   //qc
changeColor37(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: !this.state.qc})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//roche
changeColor38(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: !this.state.roche})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//roemmers
changeColor39(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: !this.state.roemmers})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//sandoz
changeColor40(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: !this.state.sandoz})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//sanofis
changeColor41(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: !this.state.sanofis})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//schering
changeColor42(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: !this.state.schering})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//stiefel
changeColor43(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: !this.state.stiefel})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//swissfarm
changeColor44(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: !this.state.swissfarm})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//talecris
changeColor45(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: !this.state.talecris})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//unipharm
changeColor46(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: !this.state.unipharm})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


   //vifor
changeColor47(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: !this.state.vifor})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//vitotal
changeColor48(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: !this.state.vitotal})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
//wyeth
changeColor49(){
 this.setState({steriscrub : false })
this.setState({eme: false})
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: !this.state.wyeth})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


  //ashonplafa
changeColor50(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : !this.state.ashonplafa})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

  //astamedica
changeColor51(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : !this.state.astamedica})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

      //bayerhealth
changeColor52(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : !this.state.bayerhealth})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

          //biokemical
changeColor53(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : !this.state.biokemical})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


          //biopas
changeColor54(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : !this.state.biopas})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

          //bonimas
changeColor55(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : !this.state.bonima})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

          //cheplapharm
changeColor56(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : !this.state.cheplapharm})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
        //church
changeColor57(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : !this.state.church})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

        //clement
changeColor58(){
 this.setState({steriscrub : false })
this.setState({eme: false})
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : !this.state.clement})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

         //drifarma
changeColor59(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : !this.state.drifarma})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

      //farmitalia
changeColor60(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : !this.state.farmitalia})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

       //grupopharma
changeColor61(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : !this.state.grupopharma})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

     //hoeschst
changeColor62(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : !this.state.hoeschst})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

     //jpharma
changeColor63(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : !this.state.jpharma})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

      //lederle
changeColor64(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : !this.state.lederle})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


          //le0
changeColor65(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : !this.state.leo})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

      //lundbeck
changeColor66(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : !this.state.lundbeck})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}
 //md
changeColor67(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : !this.state.md})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//mepha
changeColor68(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : !this.state.mepha})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//nestlecarribean
changeColor69(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : !this.state.nestlecarribean})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//om
changeColor70(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : !this.state.om})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//owen
changeColor71(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : !this.state.owen})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//panalab
changeColor72(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : !this.state.panalab})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//parkedavis
changeColor73(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : !this.state.parkedavis})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//pasmo
changeColor74(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : !this.state.pasmo})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//pharmanova
changeColor75(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : !this.state.pharmanova})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//procaps
changeColor76(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : !this.state.procaps})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//proderpharma
changeColor77(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : !this.state.proderpharma})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//rhone
changeColor78(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : !this.state.rhone})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


   //roussel
changeColor79(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : !this.state.roussel})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//rowe
changeColor80(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : !this.state.rowe})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

//searle
changeColor81(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : !this.state.searle})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

  //servier
changeColor82(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : !this.state.servier})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: false})
}

  //superior
changeColor83(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : !this.state.superior})
   this.setState({ucb : false})
   this.setState({valeant: false})
}


      //ucb
changeColor84(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : !this.state.ucb})
   this.setState({valeant: false})
}

      //valeant
changeColor85(){
this.setState({eme: false})
this.setState({steriscrub : false })
   this.setState({abott: false})
   this.setState({alcon: false})
   this.setState({almiral: false})
   this.setState({aspen: false})
   this.setState({aventis: false})
   this.setState({astra: false})
   this.setState({bago: false})
   this.setState({bayer: false})
   this.setState({bial: false})
   this.setState({bussie: false})
   this.setState({covidien: false})
   this.setState({detector: false})
   this.setState({euromed: false})
   this.setState({eurotest: false})
   this.setState({fleet: false})
   this.setState({galderma: false})
   this.setState({gnoma: false})
   this.setState({genzyme: false})
   this.setState({grifols: false})
   this.setState({grunenthal: false})
   this.setState({gsk: false})
   this.setState({lafrancol: false})
   this.setState({lasante: false})
   this.setState({lilly: false})
   this.setState({mead: false})
   this.setState({medice: false})
   this.setState({medix: false})
   this.setState({merck: false})
   this.setState({microlife: false})
   this.setState({msd: false})
   this.setState({novartis: false})
   this.setState({nutricia: false})
   this.setState({pfizer: false})
   this.setState({pharma: false})
   this.setState({promeco: false})
   this.setState({qc: false})
   this.setState({roche: false})
   this.setState({roemmers: false})
   this.setState({sandoz: false})
   this.setState({sanofis: false})
   this.setState({schering: false})
   this.setState({stiefel: false})
   this.setState({swissfarm: false})
   this.setState({talecris: false})
   this.setState({unipharm: false})
   this.setState({vifor: false})
   this.setState({vitotal: false})
   this.setState({wyeth: false})
           this.setState({ashonplafa : false})
   this.setState({astamedica : false})
   this.setState({bayerhealth : false})
   this.setState({biokemical : false})
   this.setState({biopas : false})
   this.setState({bonima : false})
   this.setState({cheplapharm : false})
   this.setState({church : false})
   this.setState({clement : false})
   this.setState({drifarma : false})
   this.setState({farmitalia : false})
   this.setState({grupopharma : false})
   this.setState({hoeschst : false})
   this.setState({jpharma : false})
   this.setState({lederle : false})
   this.setState({leo : false})
   this.setState({lundbeck : false})
   this.setState({md : false})
   this.setState({mepha : false})
   this.setState({nestlecarribean : false})
   this.setState({om : false})
   this.setState({owen : false})
   this.setState({panalab : false})
   this.setState({parkedavis : false})
   this.setState({pasmo : false})
   this.setState({pharmanova : false})
   this.setState({procaps : false})
   this.setState({proderpharma : false})
   this.setState({rhone : false})
   this.setState({roussel : false})
   this.setState({rowe : false})
   this.setState({searle : false})
   this.setState({servier : false})
   this.setState({superior : false})
   this.setState({ucb : false})
   this.setState({valeant: !this.state.valeant})
}
          //steriscrub
          changeColor86(){
           this.setState({eme: false})
               this.setState({abott: false})
               this.setState({alcon: false})
               this.setState({almiral: false})
               this.setState({aspen: false})
               this.setState({aventis: false})
               this.setState({astra: false})
               this.setState({bago: false})
               this.setState({bayer: false})
               this.setState({bial: false})
               this.setState({bussie: false})
               this.setState({covidien: false})
               this.setState({detector: false})
               this.setState({euromed: false})
               this.setState({eurotest: false})
               this.setState({fleet: false})
               this.setState({galderma: false})
               this.setState({gnoma: false})
               this.setState({genzyme: false})
               this.setState({grifols: false})
               this.setState({grunenthal: false})
               this.setState({gsk: false})
               this.setState({lafrancol: false})
               this.setState({lasante: false})
               this.setState({lilly: false})
               this.setState({mead: false})
               this.setState({medice: false})
               this.setState({medix: false})
               this.setState({merck: false})
               this.setState({microlife: false})
               this.setState({msd: false})
               this.setState({novartis: false})
               this.setState({nutricia: false})
               this.setState({pfizer: false})
               this.setState({pharma: false})
               this.setState({promeco: false})
               this.setState({qc: false})
               this.setState({roche: false})
               this.setState({roemmers: false})
               this.setState({sandoz: false})
               this.setState({sanofis: false})
               this.setState({schering: false})
               this.setState({stiefel: false})
               this.setState({swissfarm: false})
               this.setState({talecris: false})
               this.setState({unipharm: false})
               this.setState({vifor: false})
               this.setState({vitotal: false})
               this.setState({wyeth: false})
                       this.setState({ashonplafa : false})
               this.setState({astamedica : false})
               this.setState({bayerhealth : false})
               this.setState({biokemical : false})
               this.setState({biopas : false})
               this.setState({bonima : false})
               this.setState({cheplapharm : false})
               this.setState({church : false})
               this.setState({clement : false})
               this.setState({drifarma : false})
               this.setState({farmitalia : false})
               this.setState({grupopharma : false})
               this.setState({hoeschst : false})
               this.setState({jpharma : false})
               this.setState({lederle : false})
               this.setState({leo : false})
               this.setState({lundbeck : false})
               this.setState({md : false})
               this.setState({mepha : false})
               this.setState({nestlecarribean : false})
               this.setState({om : false})
               this.setState({owen : false})
               this.setState({panalab : false})
               this.setState({parkedavis : false})
               this.setState({pasmo : false})
               this.setState({pharmanova : false})
               this.setState({procaps : false})
               this.setState({proderpharma : false})
               this.setState({rhone : false})
               this.setState({roussel : false})
               this.setState({rowe : false})
               this.setState({searle : false})
               this.setState({servier : false})
               this.setState({superior : false})
               this.setState({ucb : false})
               this.setState({valeant: false})
               this.setState({steriscrub : !this.state.steriscrub })
           }



render(){
   let btn_class = this.state.eme ? "pointer" : "pointer";
   let btn_class2 = this.state.abott ? "pointer" : "pointer";
   let btn_class3 = this.state.alcon ? "pointer" : "pointer";
   let btn_class4 = this.state.almiral ? "pointer" : "pointer";
   let btn_class5 = this.state.aspen ? "pointer" : "pointer";
   let btn_class6 = this.state.aventis ? "pointer" : "pointer";
   let btn_class7 = this.state.astra ? "pointer" : "pointer";
   let btn_class8 = this.state.bago ? "pointer" : "pointer";
   let btn_class9 = this.state.bayer ? "pointer" : "pointer";
   let btn_class10 = this.state.bial ? "pointer" : "pointer";
   let btn_class11 = this.state.bussie ? "pointer" : "pointer";
   let btn_class12 = this.state.covidien ? "pointer" : "pointer";
   let btn_class13 = this.state.detector ? "pointer" : "pointer";
   let btn_class14 = this.state.euromed ? "pointer" : "pointer";
   let btn_class15 = this.state.eurotest ? "pointer" : "pointer";
   let btn_class16 = this.state.fleet ? "pointer" : "pointer";
   let btn_class17 = this.state.galderma ? "pointer" : "pointer";
   let btn_class18 = this.state.gnoma ? "pointer" : "pointer";
   let btn_class19 = this.state.genzyme ? "pointer" : "pointer";
   let btn_class20 = this.state.grifols ? "pointer" : "pointer";
   let btn_class21 = this.state.grunenthal ? "pointer" : "pointer";
   let btn_class22 = this.state.gsk ? "pointer" : "pointer";
   let btn_class23 = this.state.lafrancol ? "pointer" : "pointer";
   let btn_class24 = this.state.lasante ? "pointer" : "pointer";
   let btn_class25 = this.state.lilly ? "pointer" : "pointer";
   let btn_class26 = this.state.mead ? "pointer" : "pointer";
   let btn_class27 = this.state.medice ? "pointer" : "pointer";
   let btn_class28 = this.state.medix ? "pointer" : "pointer";
   let btn_class29 = this.state.merck ? "pointer" : "pointer";
   let btn_class30 = this.state.microlife ? "pointer" : "pointer";
   let btn_class31 = this.state.msd ? "pointer" : "pointer";
   let btn_class32 = this.state.novartis ? "pointer" : "pointer";
   let btn_class33 = this.state.nutricia ? "pointer" : "pointer";
   let btn_class34 = this.state.pfizer ? "pointer" : "pointer";
   let btn_class35 = this.state.pharma ? "pointer" : "pointer";
   let btn_class36 = this.state.promeco ? "pointer" : "pointer";
   let btn_class37 = this.state.qc ? "pointer" : "pointer";
   let btn_class38 = this.state.roche ? "pointer" : "pointer";
   let btn_class39 = this.state.roemmers ? "pointer" : "pointer";
   let btn_class40 = this.state.sandoz ? "pointer" : "pointer";
   let btn_class41 = this.state.sanofis ? "pointer" : "pointer";
   let btn_class42 = this.state.schering ? "pointer" : "pointer";
   let btn_class43 = this.state.stiefel ? "pointer" : "pointer";
   let btn_class44 = this.state.swissfarm ? "pointer" : "pointer";
   let btn_class45 = this.state.talecris ? "pointer" : "pointer";
   let btn_class46 = this.state.unipharm ? "pointer" : "pointer";
   let btn_class47 = this.state.vifor ? "pointer" : "pointer";
   let btn_class48 = this.state.vitotal ? "pointer" : "pointer";
   let btn_class49 = this.state.wyeth ? "pointer" : "pointer";
   let btn_class50 = this.state.ashonplafa ? "pointer" : "pointer";
   let btn_class51 = this.state.astamedica ? "pointer" : "pointer";
   let btn_class52 = this.state.bayerhealth ? "pointer" : "pointer";
   let btn_class53 = this.state.biokemical ? "pointer" : "pointer";
   let btn_class54 = this.state.biopas ? "pointer" : "pointer";
   let btn_class55 = this.state.bonima ? "pointer" : "pointer";
   let btn_class56 = this.state.cheplapharm ? "pointer" : "pointer";
   let btn_class57 = this.state.church ? "pointer" : "pointer";
   let btn_class58 = this.state.clement ? "pointer" : "pointer";
   let btn_class59 = this.state.drifarma ? "pointer" : "pointer";
   let btn_class60 = this.state.farmitalia ? "pointer" : "pointer";
   let btn_class61 = this.state.grupopharma ? "pointer" : "pointer";
   let btn_class62 = this.state.hoeschst ? "pointer" : "pointer";
   let btn_class63 = this.state.jpharma ? "pointer" : "pointer";
   let btn_class64 = this.state.lederle ? "pointer" : "pointer";
   let btn_class65 = this.state.leo ? "pointer" : "pointer";
   let btn_class66 = this.state.lundbeck ? "pointer" : "pointer";
   let btn_class67 = this.state.md ? "pointer" : "pointer";
   let btn_class68 = this.state.mepha ? "pointer" : "pointer";
   let btn_class69 = this.state.nestlecarribean ? "pointer" : "pointer";
   let btn_class70 = this.state.om ? "pointer" : "pointer";
   let btn_class71 = this.state.owen ? "pointer" : "pointer";
   let btn_class72 = this.state.panalab ? "pointer" : "pointer";
   let btn_class73 = this.state.parkedavis ? "pointer" : "pointer";
   let btn_class74 = this.state.pasmo ? "pointer" : "pointer";
   let btn_class75 = this.state.pharmanova? "pointer" : "pointer";
   let btn_class76 = this.state.procaps ? "pointer" : "pointer";
   let btn_class77 = this.state.proderpharma ? "pointer" : "pointer";
   let btn_class78 = this.state.rhone ? "pointer" : "pointer";
   let btn_class79 = this.state.roussel ? "pointer" : "pointer";
   let btn_class80 = this.state.rowe ? "pointer" : "pointer";
   let btn_class81 = this.state.searle ? "pointer" : "pointer";
   let btn_class82 = this.state.servier ? "pointer" : "pointer";
   let btn_class83 = this.state.superior ? "pointer" : "pointer";
   let btn_class84 = this.state.ucb ? "pointer" : "pointer";
   let btn_class85 = this.state.valeant ? "pointer" : "pointer";
   let btn_class86 = this.state.steriscrub ? "pointer" : "pointer";


        return (

<section className="pb-5">
  <div className="container">
         {/* // Fin Solo visible para smarphones */}
 {/* // Solo visible para pc */}
<div className="d-none d-sm-none d-md-block">
<div className="row">
        <div className="col-12 border-bottom text-primary">
            <h3 className="mb-3 text-primary">Socios comerciales farma </h3>
        </div>

        <div className="col-12">
            {/* <div id="carouselExampleIndicators2" className="carousel slide w-75 marginleft" data-ride="carousel">

                <div className="carousel-inner"> */}
                    {/* <div className="carousel-item active"  data-interval="5000"> */}
                        <div className="row">

                            <div className="col-md-3 mb-3">
                              <div className={btn_class} onClick={this.changeColor.bind(this)} data-toggle="modal" data-target="#exampleModal">
                                <img src={treseme}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class2} onClick={this.changeColor2.bind(this)} data-toggle="modal" data-target="#abott">
                                <img src={abott}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class3} onClick={this.changeColor3.bind(this)} data-toggle="modal" data-target="#alcon" >
                                <img src={alcon}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class4} onClick={this.changeColor4.bind(this)} data-toggle="modal" data-target="#almiral">
                                <img src={almirall}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                              <div className={btn_class5} onClick={this.changeColor5.bind(this)} data-toggle="modal" data-target="#ashonplafa">
                                <img src={ashonplafa}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class6} onClick={this.changeColor6.bind(this)} data-toggle="modal" data-target="#asopharma">
                                <img src={asopharma}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class5} onClick={this.changeColor5.bind(this)} data-toggle="modal" data-target="#aspen">
                                <img src={aspen}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class8} onClick={this.changeColor8.bind(this)} data-toggle="modal" data-target="#astamedica">
                                <img src={astamedica}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                              <div className={btn_class7} onClick={this.changeColor7.bind(this)} data-toggle="modal" data-target="#astra">
                                <img src={astra}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class8} onClick={this.changeColor8.bind(this)} data-toggle="modal" data-target="#bago">
                                <img src={bago}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                            <div className={btn_class9} onClick={this.changeColor9.bind(this)} data-toggle="modal" data-target="#bayer">
                              <img src={bayer}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class9} onClick={this.changeColor9.bind(this)} data-toggle="modal" data-target="#bayerhealth">
                              <img src={bayerhealth}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>
                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#bial">
                              <img src={BIAL}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#biokemical">
                              <img src={biokemical}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class11} onClick={this.changeColor11.bind(this)} data-toggle="modal" data-target="#biopas">
                              <img src={biopas}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class12} onClick={this.changeColor12.bind(this)} data-toggle="modal" data-target="#boehringer">
                              <img src={BOEHRINGER}  className="p-2 w-100 center" alt="..." />
                          </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class11} onClick={this.changeColor11.bind(this)} data-toggle="modal" data-target="#bussie">
                              <img src={bussie}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#cheplafarm">
                              <img src={cheplafarm}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#clement">
                              <img src={clement}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class12} onClick={this.changeColor12.bind(this)} data-toggle="modal" data-target="#medtronic">
                              <img src={MEDTRONIC}  className="p-2 w-100 center" alt="..." />
                          </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class13} onClick={this.changeColor13.bind(this)} data-toggle="modal" data-target="#detector">
                              <img src={DETECTOR}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class14} onClick={this.changeColor14.bind(this)} data-toggle="modal" data-target="#euromed">
                              <img src={EUROMED}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class15} onClick={this.changeColor15.bind(this)} data-toggle="modal" data-target="#eurotest">
                              <img src={EUROTEST}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class16} onClick={this.changeColor16.bind(this)} data-toggle="modal" data-target="#fleet">
                              <img src={FLEET}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>
                
                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class17} onClick={this.changeColor17.bind(this)} data-toggle="modal" data-target="#galderma">
                              <img src={GALDERMA}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <div className={btn_class18} onClick={this.changeColor18.bind(this)} data-toggle="modal" data-target="#gnoma">
                              <img src={gnoma}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class19} onClick={this.changeColor19.bind(this)} data-toggle="modal" data-target="#genzyme">
                              <img src={GENZYME}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class20} onClick={this.changeColor20.bind(this)} data-toggle="modal" data-target="#grifols">
                              <img src={GRIFOLS}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class17} onClick={this.changeColor17.bind(this)} data-toggle="modal" data-target="#grossman">
                              <img src={GROSSMAN}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class21} onClick={this.changeColor21.bind(this)} data-toggle="modal" data-target="#grunenthal">
                              <img src={GRUNENTHAL}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class19} onClick={this.changeColor19.bind(this)} data-toggle="modal" data-target="#grupofarma">
                              <img src={grupofarma}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <div className={btn_class22} onClick={this.changeColor22.bind(this)} data-toggle="modal" data-target="#gsk">
                              <img src={gsk}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class21} onClick={this.changeColor21.bind(this)} data-toggle="modal" data-target="#infarma">
                              <img src={INFARMA}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class22} onClick={this.changeColor22.bind(this)} data-toggle="modal" data-target="#jpharma">
                              <img src={jpharma}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class23} onClick={this.changeColor23.bind(this)} data-toggle="modal" data-target="#lasante">
                              <img src={lasante}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class24} onClick={this.changeColor24.bind(this)} data-toggle="modal" data-target="#leo">
                              <img src={leo}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class25} onClick={this.changeColor25.bind(this)} data-toggle="modal" data-target="#lilly">
                              <img src={lilly}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class26} onClick={this.changeColor26.bind(this)} data-toggle="modal" data-target="#mead">
                              <img src={MEAD}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class28} onClick={this.changeColor27.bind(this)} data-toggle="modal" data-target="#medix">
                              <img src={medix}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class27} onClick={this.changeColor27.bind(this)} data-toggle="modal" data-target="#mepha">
                              <img src={mepha}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class29} onClick={this.changeColor29.bind(this)} data-toggle="modal" data-target="#merck">
                              <img src={MERCK}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class30} onClick={this.changeColor30.bind(this)} data-toggle="modal" data-target="#mk">
                              <img src={MK}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class31} onClick={this.changeColor31.bind(this)} data-toggle="modal" data-target="#msd">
                              <img src={msd}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class32} onClick={this.changeColor32.bind(this)} data-toggle="modal" data-target="#nestlecaribean">
                              <img src={nestlecaribean}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class32} onClick={this.changeColor32.bind(this)} data-toggle="modal" data-target="#novartis">
                              <img src={novartis}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#nutricia">
                              <img src={NUTRICIA}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#om">
                              <img src={om}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#organon">
                              <img src={ORGANON}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class32} onClick={this.changeColor32.bind(this)} data-toggle="modal" data-target="#owen">
                              <img src={owen}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        {/* <div className="row">
                          
                        </div> */}
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#pg">
                              <img src={PG}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#panalab">
                              <img src={panalab}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#pasmo">
                              <img src={pasmo}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class34} onClick={this.changeColor34.bind(this)} data-toggle="modal" data-target="#pfizer">
                              <img src={pfizer}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>

                          <div className="col-md-3 mb-3">
                            <div className={btn_class36} onClick={this.changeColor36.bind(this)} data-toggle="modal" data-target="#promeco">
                              <img src={PROMECO}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>


                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class35} onClick={this.changeColor35.bind(this)} data-toggle="modal" data-target="#proder">
                              <img src={proderfarma}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <div className={btn_class37} onClick={this.changeColor37.bind(this)} data-toggle="modal" data-target="#qc">
                              <img src={QC}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class38} onClick={this.changeColor38.bind(this)} data-toggle="modal" data-target="#roche">
                              <img src={roche}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class40} onClick={this.changeColor40.bind(this)} data-toggle="modal" data-target="#sandoz">
                              <img src={sandoz}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class41} onClick={this.changeColor41.bind(this)} data-toggle="modal" data-target="#sanofis">
                              <img src={sanofis}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class39} onClick={this.changeColor39.bind(this)} data-toggle="modal" data-target="#roemmers">
                              <img src={roemmers}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class40} onClick={this.changeColor40.bind(this)} data-toggle="modal" data-target="#rowe">
                              <img src={rowe}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class44} onClick={this.changeColor44.bind(this)} data-toggle="modal" data-target="#servier">
                              <img src={servier}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class42} onClick={this.changeColor42.bind(this)} data-toggle="modal" data-target="#schering">
                              <img src={schering}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class43} onClick={this.changeColor43.bind(this)} data-toggle="modal" data-target="#steriscrub">
                              <img src={steriscrub}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class35} onClick={this.changeColor35.bind(this)} data-toggle="modal" data-target="#superior">
                              <img src={superior}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div>

                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                        <div className="col-md-3 mb-3">
                            <div className={btn_class44} onClick={this.changeColor44.bind(this)} data-toggle="modal" data-target="#swissfarm">
                              <img src={SWISSFARM}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class43} onClick={this.changeColor43.bind(this)} data-toggle="modal" data-target="#tecnoquimicas">
                              <img src={tecnoquimicas}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class46} onClick={this.changeColor46.bind(this)} data-toggle="modal" data-target="#unipharm">
                              <img src={UNIPHARM}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class35} onClick={this.changeColor35.bind(this)} data-toggle="modal" data-target="#pharma">
                              <img src={PHARMACIA}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class47} onClick={this.changeColor47.bind(this)} data-toggle="modal" data-target="#vifor">
                              <img src={VIFOR}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class48} onClick={this.changeColor48.bind(this)} data-toggle="modal" data-target="#vitotal">
                              <img src={VITOTAL}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)} data-toggle="modal" data-target="#wyeth">
                              <img src={Wye}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}


                {/* </div>
            </div> */}

            {/* <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
              <i className="fas fa-angle-left text-primary fa-2x"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
              <i className="fas fa-angle-right text-primary fa-2x"></i>
              <span className="sr-only">Next</span>
            </a> */}
        </div>
    </div>
</div>
</div>
  


   {/* <!-- Modal 1--> */}
   <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
            <img src={eme}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



{/* <!-- Modal 2--> */}
<div className="modal fade" id="abott" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor2.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={abottNormal}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 3--> */}
<div className="modal fade" id="alcon" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor3.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAlcon}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 4--> */}
<div className="modal fade" id="almiral" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor4.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAlmiral}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="aspen" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAspen}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 6--> */}
<div className="modal fade" id="aventis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor6.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBetafarm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 7--> */}
<div className="modal fade" id="astra" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor7.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAstra}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 8--> */}
<div className="modal fade" id="bago" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor8.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBago}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 9--> */}
<div className="modal fade" id="bayer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor9.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBayer}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 10--> */}
<div className="modal fade" id="bial" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor10.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBial}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 11--> */}
<div className="modal fade" id="bussie" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor11.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMEDTRONIC}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 12--> */}
<div className="modal fade" id="medtronic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor11.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMEDTRONIC}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 12--> */}
{/* <div className="modal fade" id="covidien" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor12.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMEDTRONIC}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div> */}


{/* <!-- Modal 13--> */}
<div className="modal fade" id="detector" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor13.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDetector}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 14--> */}
<div className="modal fade" id="euromed" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor14.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoEuromed}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 15--> */}
<div className="modal fade" id="eurotest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor15.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoEurotest}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



{/* <!-- Modal 16--> */}
<div className="modal fade" id="fleet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor16.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFleet}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 17--> */}
{/* <div className="modal fade" id="galderma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor17.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGalderma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div> */}


 {/* <!-- Modal 18--> */}
<div className="modal fade" id="gnoma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor18.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGnomma}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
  
  
{/* <!-- Modal 19--> */}
<div className="modal fade" id="genzyme" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor19.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGenzyme}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 20--> */}
<div className="modal fade" id="grifols" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor20.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGrifols}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 21--> */}
<div className="modal fade" id="grunenthal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor21.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGrunenthal}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 22--> */}
 <div className="modal fade" id="gsk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor22.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGsk}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 23--> */}
 <div className="modal fade" id="lasante" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor23.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
        <div className="row">
           <div className="col-md-12">
           <img src={logolasante}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 24--> */}
 <div className="modal fade" id="lafrancol" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor24.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
        <div className="row">
           <div className="col-md-12">
           <img src={logoLafrancol}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 25--> */}
 <div className="modal fade" id="lilly" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor25.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoLilly}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 26--> */}
<div className="modal fade" id="mead" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor26.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMead}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 27--> */}
<div className="modal fade" id="medice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor27.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMedice}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



 {/* <!-- Modal 28--> */}
 <div className="modal fade" id="medix" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor28.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMexix}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 29--> */}
<div className="modal fade" id="merck" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor29.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMerck}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 30--> */}
<div className="modal fade" id="microlife" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor30.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMicrolife}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



 {/* <!-- Modal 31--> */}
 <div className="modal fade" id="msd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor31.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMsd}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 32--> */}
 <div className="modal fade" id="novartis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor32.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNovartis}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 33--> */}
<div className="modal fade" id="nutricia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor33.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNutricia}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 34--> */}
 <div className="modal fade" id="pfizer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor34.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPfizer}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 35--> */}
 <div className="modal fade" id="pharma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor35.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPharma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 36--> */}
<div className="modal fade" id="promeco" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor36.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPromeco}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 37--> */}
<div className="modal fade" id="qc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor37.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoQC}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 38--> */}
 <div className="modal fade" id="roche" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor38.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRoche}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 39--> */}
 <div className="modal fade" id="roemmers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor39.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRoemmers}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 40--> */}
 <div className="modal fade" id="sandoz" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor40.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSandoz}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 41--> */}
 <div className="modal fade" id="sanofis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor41.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSanofi}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 42--> */}
 <div className="modal fade" id="schering" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor42.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSchering}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 43--> */}
 <div className="modal fade" id="stiefel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor43.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoStiefel}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 44--> */}
<div className="modal fade" id="swissfarm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor44.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSwissfarm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 45--> */}
 <div className="modal fade" id="talecris" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor45.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTalecris}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
 
 {/* <!-- Modal 46--> */}
 <div className="modal fade" id="unipharm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor46.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoUnipharm}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 47--> */}
<div className="modal fade" id="vifor" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor47.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVifor}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 48--> */}
<div className="modal fade" id="vitotal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor48.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVitotal}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 49--> */}
<div className="modal fade" id="wyeth" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor49.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFungiplus}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 50--> */}
<div className="modal fade" id="ashonplafa" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor50.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAshonplafa}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 51--> */}
<div className="modal fade" id="astamedica" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor51.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAstamedica}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 52--> */}
<div className="modal fade" id="bayerhealth" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor52.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBayerHealth}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 53--> */}
<div className="modal fade" id="biokemical" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor53.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBiokemical}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 54--> */}
<div className="modal fade" id="biopas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor54.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBiopas}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



{/* <!-- Modal 55--> */}
<div className="modal fade" id="bonima" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor55.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBonima}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 56--> */}
<div className="modal fade" id="cheplapharm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor56.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoCheplapharm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 57--> */}
<div className="modal fade" id="church" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor57.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoChurch}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 58--> */}
<div className="modal fade" id="clement" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor58.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoClement}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 59--> */}
<div className="modal fade" id="drifarma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor59.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDrifarma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 60--> */}
<div className="modal fade" id="farmitalia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor60.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logofarmitalia}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 61--> */}
<div className="modal fade" id="grupofarma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor61.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logogrupofarma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 62--> */}
<div className="modal fade" id="hoest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor62.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logohoest}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 63--> */}
<div className="modal fade" id="jpharma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor63.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logojpharma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 64--> */}
<div className="modal fade" id="lederle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor64.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logolederle}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 65--> */}
<div className="modal fade" id="leo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor65.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoleo}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 66--> */}
<div className="modal fade" id="lundbeck" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor66.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logolundbeck}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 67--> */}
<div className="modal fade" id="md" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor67.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logomd}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 68--> */}
<div className="modal fade" id="mepha" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor68.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logomepha}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 69--> */}
<div className="modal fade" id="nestlecarribean" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor69.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logonestlecaribean}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 70--> */}
<div className="modal fade" id="om" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor70.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoom}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 71--> */}
<div className="modal fade" id="owen" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor71.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoowen}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 72--> */}
<div className="modal fade" id="panalab" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor72.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logopanalab}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 73--> */}
<div className="modal fade" id="parkedavis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor73.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoparkedavis}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 74--> */}
<div className="modal fade" id="pasmo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor74.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logopasmo}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 75--> */}
<div className="modal fade" id="pharmanova" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor75.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logopharmanova}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 75--> */}
<div className="modal fade" id="asopharma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor75.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAsopharma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 76--> */}
<div className="modal fade" id="procaps" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor76.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoprocaps}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 77--> */}
<div className="modal fade" id="proderpharma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor77.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoproderfarma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 78--> */}
<div className="modal fade" id="rhone" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor78.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logorhone}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 79--> */}
<div className="modal fade" id="roussel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor79.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoroussel}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 80--> */}
<div className="modal fade" id="rowe" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor80.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logorowe}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 81--> */}
<div className="modal fade" id="searle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor81.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logosearle}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 82--> */}
<div className="modal fade" id="servier" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor82.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoservier}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 83--> */}
<div className="modal fade" id="superior" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor83.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logosuperior}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 84--> */}
<div className="modal fade" id="ucb" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor84.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoucb}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 85--> */}
<div className="modal fade" id="valeant" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor85.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logovaleant}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="steriscrub" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSteriscrub}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="ashonplafa" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAshonplafa}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 86--> */}
<div className="modal fade" id="boehringer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBoeh}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="cheplafarm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoCheplapharm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="grossman" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGrossman}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="infarma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoInfarma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="mk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMK}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="organon" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoOrganon}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="pg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPG}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="proder" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoProdes}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 86--> */}
<div className="modal fade" id="tecnoquimicas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor86.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTecnoquimicas}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


</section>
  )
}
}export default Farma