/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import alka from '../../img/CONSUMO1/ALKA SELTZER.png';
//import carefree from '../../img/CONSUMO1/CAREFREE.png';
import casillero from '../../img/CONSUMO1/CASILLERO DEL DIABLO.png';
import airwick from '../../img/CONSUMO1/Air Wick.png';
import COLOMBINA from '../../img/CONSUMO1/COLOMBINA.png';
import Aleve from '../../img/CONSUMO1/Aleve.png';
import DERMANATURAL from '../../img/CONSUMO1/DERMANATURAL.png';
import desitin from '../../img/CONSUMO1/DESITIN.png';
import DVANT from '../../img/CONSUMO1/DVANT.png';
import dove from '../../img/CONSUMO1/DOVE.png';
import JIMADOR from '../../img/CONSUMO1/EL JIMADOR.png';
import evenflo from '../../img/CONSUMO1/EVENFLO.png';
import BIC from '../../img/CONSUMO1/BIC.png';
import glad from '../../img/CONSUMO1/GLAD.png';
import HERRADURA from '../../img/CONSUMO1/HERRADURA.png';
import hersheys from '../../img/CONSUMO1/HERSHEYS.png'; 
import Bug  from '../../img/CONSUMO1/Bug Off.png';
import HYDE from '../../img/CONSUMO1/HYDE PARK.png';
import johnson from '../../img/J&J.jpg';
import JUICY from '../../img/CONSUMO1/JUICY FRUIT.png';
import KISSES from '../../img/CONSUMO1/KISSES.png';
import listerine from '../../img/CONSUMO1/LISTERINE.png';
import lubriderm from '../../img/CONSUMO1/LUBRIDERM.png';
import LYSOL from '../../img/CONSUMO1/LYSOL.png';
import MM from '../../img/CONSUMO1/M&MS.png';
import MADONNA from '../../img/CONSUMO1/MADONNA.png';
import Cardio from '../../img/CONSUMO1/CARDIOaspirina.png';
import MCCORMICK from '../../img/CONSUMO1/MCCORMICK.png';
import MILKY from '../../img/CONSUMO1/MILKY WAY.png';
import nestle from '../../img/CONSUMO1/NESTLE.png';
import nivea from '../../img/CONSUMO1/NIVEA.png';
import NODOR from '../../img/CONSUMO1/NODOR.png';
import ORBIT from '../../img/CONSUMO1/ORBIT.png';
import PEDIGREE from '../../img/CONSUMO1/PEDIGREE.png';
import charisma from '../../img/CONSUMO1/CHARISMA LOGO color-01.png';
import PRINGLES from '../../img/CONSUMO1/PRINGLES.png';
import clorox from '../../img/CONSUMO1/Clorox Company.png';
import SANGRE from '../../img/CONSUMO1/SANGRE DE TORO.png';
import clos from '../../img/CONSUMO1/Clos del Pirque.png';
import skittles from '../../img/CONSUMO1/SKITTLES.png';
import SNICKERS from '../../img/CONSUMO1/SNICKERS.png';
import splenda from '../../img/CONSUMO1/SPLENDA.png';
import STARBURST from '../../img/CONSUMO1/STARBURST.png';
import STAYFREE from '../../img/CONSUMO1/STAYFREE.png';
import SUMMER from '../../img/CONSUMO1/SUMMERS EVE.png';
import coronas from '../../img/CONSUMO1/Coronas.png';
import TWIX from '../../img/CONSUMO1/TWIX.png';
import TYLENOL from '../../img/CONSUMO1/TYLENOL.png';
import donmelchor from '../../img/CONSUMO1/Don Melchor.png';
import VITACREME from '../../img/CONSUMO1/VITACREME.png';
import WHISKAS from '../../img/CONSUMO1/WHISKAS.png';
import doublemint from '../../img/CONSUMO1/Double Mint.png';
import freegells from '../../img/CONSUMO1/Freegells.png';
import french from '../../img/CONSUMO1/French.png';
import frontera from '../../img/CONSUMO1/Frontera.png';
import harpic from '../../img/CONSUMO1/Harpic.png';
import jalisco from '../../img/CONSUMO1/Jalisco.png';
import jellico from '../../img/CONSUMO1/Jellico.png';
import chula from '../../img/CONSUMO1/La Chula.png';
import lactacyd from '../../img/CONSUMO1/Lactacyd.png';
import nerds from '../../img/CONSUMO1/Nerds.png';
import marques from '../../img/CONSUMO1/Marqués de Casa Concha.png';
import niveamen from '../../img/CONSUMO1/Nivea for Men.png';
import OGX from '../../img/CONSUMO1/OGX.png';
import oxy from '../../img/CONSUMO1/Oxy.png';
import redbull from '../../img/CONSUMO1/Red Bull.png';
import robert from '../../img/CONSUMO1/Robert Mondavi.png';
import roy from '../../img/CONSUMO1/Roy vigan color-01.png';
import sweetarts from '../../img/CONSUMO1/Sweetarts.png';
import tabcin from '../../img/CONSUMO1/Tabcin.png';
import toñac from '../../img/CONSUMO1/Tona Clásica.png';
import toñal from '../../img/CONSUMO1/Tona Light.png';
import trio from '../../img/CONSUMO1/Trio.png';
import trojan from '../../img/CONSUMO1/Trojan.png';
import vanish from '../../img/CONSUMO1/Vanish.png';
import venadito from '../../img/CONSUMO1/Venadito.png';
import victoria from '../../img/CONSUMO1/Victoria Frost.png';
import vigui from '../../img/CONSUMO1/Vigui.png';
import Winterfresh from '../../img/CONSUMO1/Winterfresh.png';
import WoodBridge from '../../img/CONSUMO1/WoodBridge.png';
import cleananclear from '../../img/CONSUMO1/CLEAN & CLEAR.png';
import Ferro from '../../img/CONSUMO1/FERRERO ROCHER.png';
import Hor from '../../img/CONSUMO1/HORMEL.png';
import Mar from '../../img/CONSUMO1/MARUCHAN.png';
import pon from '../../img/CONSUMO1/PONDS.png';
import sed from '../../img/CONSUMO1/SEDAL.png';
import tres from '../../img/CONSUMO1/TRESEMMÉ.png';
import twix from '../../img/CONSUMO1/TWIX.png';
import uni from '../../img/CONSUMO1/UNILEVER.png';
import wrig from '../../img/CONSUMO1/WRIGLEY.png';
import concha from '../../img/CONSUMO1/CONCHA Y TORO.png';
import rex from '../../img/CONSUMO1/REXONA.png';


// imagenes de los modals
import logoAlka from '../../img/consumo/ALKA SELTZER.png';
import logoCarefree from '../../img/consumo/carefree.png';
import logoCasillero from '../../img/consumo/CASILLERO DEL DIABLO.png';
import logoClean from '../../img/consumo/CLEAN & CLEAR.png';
import logoDesitin from '../../img/consumo/desitin.png';
import logoDove from '../../img/consumo/Aleve.png';
import logoEvenflo from '../../img/consumo/evenflo.png';
import logoGlad from '../../img/consumo/glad.png';
import logoHersheys from '../../img/consumo/hersheys.png';
//import logoJohn from '../../img/consumo/JOHNSON & JOHNSON.png';
import logoJohn from '../../img/J&J.jpg';
import logoJuicy from '../../img/consumo/JUICY FRUIT.png';
import logoListerine from '../../img/consumo/LISTERINE.png';
import logoLubriderm from '../../img/consumo/LUBRIDERM.png';
import logoNestle from '../../img/consumo/NESTLÉ.png';
import logoNivea from '../../img/consumo/NIVEA.png';
import logoPonds from '../../img/consumo/CHARISMA LOGO color-01.png';
import logoRexona from '../../img/consumo/Clorox Company.png';
import logosedal from '../../img/consumo/Clos del Pirque.png';
import logoSkittles from '../../img/consumo/SKITTLES.png';
import logoSplenda from '../../img/consumo/SPLENDA.png';
import logoTresemme from '../../img/consumo/Coronas.png';
import logoTylenol from '../../img/consumo/TYLENOL.png';
import logoUnilever from '../../img/consumo/Don Melchor.png';
import logoColombina from '../../img/consumo/COLOMBINA.png';
import logoConcha from '../../img/consumo/Air Wick.png';
import logoDerma from '../../img/consumo/DERMANATURAL.png';
import logoDvant from '../../img/consumo/DVANT.png';
import logoJimador from '../../img/consumo/EL JIMADOR.png';
import logoBic from '../../img/consumo/BIC.png';
import logoHerradura from '../../img/consumo/HERRADURA.png';
import logoHormel from '../../img/consumo/Bug Off.png';
import logoHyde from '../../img/consumo/HYDE PARK.png';
import logoKisses from '../../img/consumo/KISSES.png';
import logoLysol from '../../img/consumo/LYSOL.png';
import logoMm from '../../img/consumo/M&MS.png';
import logoMadonna from '../../img/consumo/MADONNA.png';
import logoMaruchan from '../../img/consumo/CARDIOaspirina.png';
import logoMcormick from '../../img/consumo/MCCORMICK.png';
import logoMilky from '../../img/consumo/MILKY WAY.png';
import logoNodor from '../../img/consumo/NODOR.png';
import logoOrbit from '../../img/consumo/ORBIT.png';
import logoPedigree from '../../img/consumo/PEDIGREE.png';
import logoPringles from '../../img/consumo/PRINGLES.png';
import logoSangre from '../../img/consumo/SANGRE DE TORO.png';
import logoSnickers from '../../img/consumo/SNICKERS.png';
import logoStar from '../../img/consumo/STARBURST.png';
import logoStayfree from '../../img/consumo/STAYFREE.png';
import logoSummer from '../../img/consumo/SUMMERS EVE.png';
import logoTwix from '../../img/consumo/TWIX.png';
import logoVitacreme from '../../img/consumo/VITACREME.png';
import logoWhiskas from '../../img/consumo/WHISKAS.png';
import logoWrigley from '../../img/consumo/Double Mint.png';
import logoFreegells from '../../img/consumo/Freegells.png';
import logoFrench from '../../img/consumo/French.png';
import logoFrontera from '../../img/consumo/Frontera.png';
import logoHarpic from '../../img/consumo/Harpic.png';
import logoJalisco from '../../img/consumo/Jalisco.png';
import logoJellico from '../../img/consumo/Jellico.png';
import logoChula from '../../img/consumo/La Chula.png';
import logoLactacyd from '../../img/consumo/Lactacyd.png';
import logoMarques from '../../img/consumo/Marqués de Casa Concha.png';
import logoNerds from '../../img/consumo/Nerds.png';
import logoNiveaMen from '../../img/consumo/Nivea for Men.png';
import logoOGX from '../../img/consumo/OGX.png';
import logoOxy from '../../img/consumo/Oxy.png';
import logoRedbull from '../../img/consumo/Red Bull.png';
import logoRobert from '../../img/consumo/Robert Mondavi.png';
import logoRoy from '../../img/consumo/Roy vigan color-01.png';
import logoSweetarts from '../../img/consumo/Sweetarts.png';
import logoTabcin from '../../img/consumo/Tabcin.png';
import logoTonal from '../../img/consumo/Tona Light.png';
import logoTonac from '../../img/consumo/Toña Clásica.png';
import logoTrio from '../../img/consumo/Trio.png';
import logoTrojan from '../../img/consumo/Trojan.png';
import logoVanish from '../../img/consumo/Vanish.png';
import logoVenadito from '../../img/consumo/Venadito.png';
import logoVictoria from '../../img/consumo/Victoria Frost.png';
import logoVigui from '../../img/consumo/Vigui.png';
import logoWinterfresh from '../../img/consumo/Winterfresh.png';
import logoWoodbridge from '../../img/consumo/WoodBridge.png';
import logocleananclear from '../../img/consumo/CLEAN & CLEAR.png';
import logoDov from '../../img/consumo/dove.png';
import logoCon from '../../img/consumo/CONCHA Y TORO.png';
import logoFerro from '../../img/consumo/FERRERO ROCHER.png';
import logoHor from '../../img/consumo/HORMEL.png';
import logoMar from '../../img/consumo/MARUCHAN.png';
import logoPon from '../../img/consumo/PONDS.png';
import logoSed from '../../img/consumo/SEDAL.png';
import logoTres from '../../img/consumo/TRESEMMÉ.png';
import logoUni from '../../img/consumo/UNILEVER.png';
import logoWrig from '../../img/consumo/WRIGLEY.png';
import logoRex from '../../img/consumo/REXONA.png';

class Consumo extends React.Component {
constructor(){
         super();

         this.state = {
          alka: false,
          axe: false,
          carefree: false,
          casillero: false,
          clean: false,
          colombina: false,
          concha: false,
          derma: false,
          desitin: false,
          dove: false,
          dvant: false,
          jimador: false,
          evenflo: false,
          ferrero: false,
          glad: false,
          herradura: false,
          hershey: false,
          hormel: false,
          hyde: false,
          john: false,
          juisy: false,
          keri: false,
          kisses: false,
          listerine: false,
          lubriderm: false ,       
          lysol: false,
          mm: false,
          madonna: false,
          maruchan: false,
          mcormick: false,
          milky: false,
          nestle: false,
          nivea: false,
          nodor: false,
          orbit: false,
          pedigree: false,
          ponds: false,
          pringles: false,
          rexona: false,
          sangre: false,
          sedal: false,
          skittles: false,
          snickers: false,
          splenda: false,
          star: false,
          stayfree: false,
          summer: false,
          treseme: false,
          twix: false,
          tylenol: false,
          unilever: false,
          vitacreme: false,
          whiskas: false,
          wrigley: false ,  
          
         }
    }
//alka
    changeColor(){
        this.setState({alka: !this.state.alka})
        this.setState({axe: false})
        this.setState({freegells:false})
        this.setState({french:false})
        this.setState({frontera:false})
        this.setState({harpic:false})
        this.setState({jalisco:false})
        this.setState({jellico:false})
        this.setState({chula :false})
        this.setState({lactacyd:false})
        this.setState({marques:false})
        this.setState({nerds :false})
        this.setState({niveamen:false})
        this.setState({ogx  :false})
        this.setState({oxy  :false})
        this.setState({redbul:false})
        this.setState({robert:false})
        this.setState({roy :false})
        this.setState({sweeta:false})
        this.setState({tabcin:false})
        this.setState({toñac :false})
        this.setState({toñal :false})
        this.setState({trio :false})
        this.setState({trojan:false})
        this.setState({vanish:false})
        this.setState({venadito:false})
        this.setState({victor:false})
        this.setState({vigui :false})
        this.setState({winterfresh:false})
        this.setState({woodbridge:false})
        this.setState({carefree: false})
        this.setState({casillero: false})
        this.setState({clean: false})
        this.setState({colombina: false})
        this.setState({concha: false})
        this.setState({derma: false})
        this.setState({desitin: false})
        this.setState({dove: false})
        this.setState({dvant: false})
        this.setState({jimador: false})
        this.setState({evenflo: false})
        this.setState({ferrero: false})
        this.setState({glad: false})
        this.setState({herradura: false})
        this.setState({hershey: false})
        this.setState({hormel: false})
        this.setState({hyde: false})
        this.setState({john: false})
        this.setState({juisy: false})
        this.setState({keri: false})
        this.setState({kisses: false})
        this.setState({listerine: false})
        this.setState({lubriderm: false})        
        this.setState({lysol: false})
        this.setState({mm: false})
        this.setState({madonna: false})
        this.setState({maruchan: false})
        this.setState({mcormick: false})
        this.setState({milky: false})
        this.setState({nestle: false})
        this.setState({nivea: false})
        this.setState({nodor: false})
        this.setState({orbit: false})
        this.setState({pedigree: false})
        this.setState({ponds: false})
        this.setState({pringles: false})
        this.setState({rexona: false})
        this.setState({sangre: false})
        this.setState({sedal: false})
        this.setState({skittles: false})
        this.setState({snickers: false})
        this.setState({splenda: false})
        this.setState({star: false})
        this.setState({stayfree: false})
        this.setState({summer: false})
        this.setState({treseme: false})
        this.setState({twix: false})
        this.setState({tylenol: false})
        this.setState({unilever: false})
        this.setState({vitacreme: false})
        this.setState({whiskas: false})
        this.setState({wrigley: false})

    }
    //carefree
    changeColor2(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: !this.state.carefree})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //casillero
    changeColor3(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: !this.state.casillero})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //clean
    changeColor4(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: !this.state.clean})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //colombina
    changeColor5(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: !this.state.colombina})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})    }
    //concha
    changeColor6(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: !this.state.concha})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //derma
    changeColor7(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: !this.state.derma})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //desitin
    changeColor8(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: !this.state.desitin})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
//dove
    changeColor9(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: !this.state.dove})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //dvant
    changeColor10(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: !this.state.dvant})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //jimador
    changeColor11(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: !this.state.jimador})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
    }
    //evenflo
changeColor12(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: !this.state.evenflo})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
// ferrero
changeColor13(){
  this.setState({alka: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: !this.state.ferrero})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
//glad
changeColor14(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: !this.state.glad})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
//herradura
changeColor15(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: !this.state.herradura})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
//hersheys
changeColor16(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: !this.state.hershey})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
//hormel
changeColor17(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: !this.state.hormel})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
}
//hyde
changeColor18(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: !this.state.hyde})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  }
  //johnson
  changeColor19(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: !this.state.john})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  // juicy
  changeColor20(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: !this.state.juisy})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }

  //kisses
  changeColor21(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: !this.state.kisses})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})

  }
  //listerine
  changeColor22(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: !this.state.listerine})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  } 
  //lubriderm
  changeColor23(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: !this.state.lubriderm})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  } 
  //lysol
  changeColor24(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: !this.state.lysol})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
//mm
  changeColor25(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: !this.state.mm})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
//madonna
  changeColor26(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: !this.state.madonna})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //maruchan
  changeColor27(){ 
    this.setState({alka: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: !this.state.maruchan})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //mcormick
  changeColor28(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: !this.state.mcormick})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //milky
  changeColor29(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: !this.state.milky})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //nestle
  changeColor30(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: !this.state.nestle})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //nivea
  changeColor31(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: !this.state.nivea})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //nodor
  changeColor32(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: !this.state.nodor})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //orbit
  changeColor33(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: !this.state.orbit})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //pedigree
  changeColor34(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: !this.state.pedigree})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //ponds
  changeColor35(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: !this.state.ponds})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //pringles
  changeColor36(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: !this.state.pringles})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //rexona
  changeColor37(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: !this.state.rexona})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //sangre
  changeColor38(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: !this.state.sangre})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //sedal
  changeColor39(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: !this.state.sedal})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //skittles
  changeColor40(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: !this.state.skittles})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})  
  }
  //snickers
  changeColor41(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: !this.state.snickers})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //splenda
  changeColor42(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: !this.state.splenda})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //starburst
  changeColor43(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: !this.state.star})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //stayfree
  changeColor44(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: !this.state.stayfree})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})  }
  // summers eve
  changeColor45(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: !this.state.summer})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})  }
  //tresemme
  changeColor46(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: !this.state.treseme})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //twix
  changeColor47(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: !this.state.twix})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //tylenol
  changeColor48(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: !this.state.tylenol})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //unilever
  changeColor49(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: !this.state.unilever})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //vitacreme
  changeColor50(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: !this.state.vitacreme})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
  }
  //whiskas
  changeColor51(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: !this.state.whiskas})
    this.setState({wrigley: false})  
  }
  //wrigley
  changeColor52(){
    this.setState({alka: false})
    this.setState({freegells:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: !this.state.wrigley})  
  }

  //freegells
  changeColor53(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
    this.setState({freegells:!this.state.freegells})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
  }

    //french
    changeColor54(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
      this.setState({freegells:false})
      this.setState({french:!this.state.french})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
    }

        //frontera
        changeColor55(){
          this.setState({alka: false})
          this.setState({axe: false})
          this.setState({carefree: false})
          this.setState({casillero: false})
          this.setState({clean: false})
          this.setState({colombina: false})
          this.setState({concha: false})
          this.setState({derma: false})
          this.setState({desitin: false})
          this.setState({dove: false})
          this.setState({dvant: false})
          this.setState({jimador: false})
          this.setState({evenflo: false})
          this.setState({ferrero: false})
          this.setState({glad: false})
          this.setState({herradura: false})
          this.setState({hershey: false})
          this.setState({hormel: false})
          this.setState({hyde: false})
          this.setState({john: false})
          this.setState({juisy: false})
          this.setState({keri: false})
          this.setState({kisses: false})
          this.setState({listerine: false})
          this.setState({lubriderm: false})        
          this.setState({lysol: false})
          this.setState({mm: false})
          this.setState({madonna: false})
          this.setState({maruchan: false})
          this.setState({mcormick: false})
          this.setState({milky: false})
          this.setState({nestle: false})
          this.setState({nivea: false})
          this.setState({nodor: false})
          this.setState({orbit: false})
          this.setState({pedigree: false})
          this.setState({ponds: false})
          this.setState({pringles: false})
          this.setState({rexona: false})
          this.setState({sangre: false})
          this.setState({sedal: false})
          this.setState({skittles: false})
          this.setState({snickers: false})
          this.setState({splenda: false})
          this.setState({star: false})
          this.setState({stayfree: false})
          this.setState({summer: false})
          this.setState({treseme: false})
          this.setState({twix: false})
          this.setState({tylenol: false})
          this.setState({unilever: false})
          this.setState({vitacreme: false})
          this.setState({whiskas: false})
          this.setState({wrigley: false})
          this.setState({freegells:false})
          this.setState({french:false})
          this.setState({frontera:!this.state.frontera})
          this.setState({harpic:false})
          this.setState({jalisco:false})
          this.setState({jellico:false})
          this.setState({chula :false})
          this.setState({lactacyd:false})
          this.setState({marques:false})
          this.setState({nerds :false})
          this.setState({niveamen:false})
          this.setState({ogx  :false})
          this.setState({oxy  :false})
          this.setState({redbul:false})
          this.setState({robert:false})
          this.setState({roy :false})
          this.setState({sweeta:false})
          this.setState({tabcin:false})
          this.setState({toñac :false})
          this.setState({toñal :false})
          this.setState({trio :false})
          this.setState({trojan:false})
          this.setState({vanish:false})
          this.setState({venadito:false})
          this.setState({victor:false})
          this.setState({vigui :false})
          this.setState({winterfresh:false})
          this.setState({woodbridge:false})
        }
        //harpic
        changeColor56(){
          this.setState({alka: false})
          this.setState({axe: false})
          this.setState({carefree: false})
          this.setState({casillero: false})
          this.setState({clean: false})
          this.setState({colombina: false})
          this.setState({concha: false})
          this.setState({derma: false})
          this.setState({desitin: false})
          this.setState({dove: false})
          this.setState({dvant: false})
          this.setState({jimador: false})
          this.setState({evenflo: false})
          this.setState({ferrero: false})
          this.setState({glad: false})
          this.setState({herradura: false})
          this.setState({hershey: false})
          this.setState({hormel: false})
          this.setState({hyde: false})
          this.setState({john: false})
          this.setState({juisy: false})
          this.setState({keri: false})
          this.setState({kisses: false})
          this.setState({listerine: false})
          this.setState({lubriderm: false})        
          this.setState({lysol: false})
          this.setState({mm: false})
          this.setState({madonna: false})
          this.setState({maruchan: false})
          this.setState({mcormick: false})
          this.setState({milky: false})
          this.setState({nestle: false})
          this.setState({nivea: false})
          this.setState({nodor: false})
          this.setState({orbit: false})
          this.setState({pedigree: false})
          this.setState({ponds: false})
          this.setState({pringles: false})
          this.setState({rexona: false})
          this.setState({sangre: false})
          this.setState({sedal: false})
          this.setState({skittles: false})
          this.setState({snickers: false})
          this.setState({splenda: false})
          this.setState({star: false})
          this.setState({stayfree: false})
          this.setState({summer: false})
          this.setState({treseme: false})
          this.setState({twix: false})
          this.setState({tylenol: false})
          this.setState({unilever: false})
          this.setState({vitacreme: false})
          this.setState({whiskas: false})
          this.setState({wrigley: false})
          this.setState({freegells:false})
          this.setState({french:false})
          this.setState({frontera:false})
          this.setState({harpic:!this.state.harpic})
          this.setState({jalisco:false})
          this.setState({jellico:false})
          this.setState({chula :false})
          this.setState({lactacyd:false})
          this.setState({marques:false})
          this.setState({nerds :false})
          this.setState({niveamen:false})
          this.setState({ogx  :false})
          this.setState({oxy  :false})
          this.setState({redbul:false})
          this.setState({robert:false})
          this.setState({roy :false})
          this.setState({sweeta:false})
          this.setState({tabcin:false})
          this.setState({toñac :false})
          this.setState({toñal :false})
          this.setState({trio :false})
          this.setState({trojan:false})
          this.setState({vanish:false})
          this.setState({venadito:false})
          this.setState({victor:false})
          this.setState({vigui :false})
          this.setState({winterfresh:false})
          this.setState({woodbridge:false})
        }

        //jalisco
        changeColor57(){
          this.setState({alka: false})
          this.setState({axe: false})
          this.setState({carefree: false})
          this.setState({casillero: false})
          this.setState({clean: false})
          this.setState({colombina: false})
          this.setState({concha: false})
          this.setState({derma: false})
          this.setState({desitin: false})
          this.setState({dove: false})
          this.setState({dvant: false})
          this.setState({jimador: false})
          this.setState({evenflo: false})
          this.setState({ferrero: false})
          this.setState({glad: false})
          this.setState({herradura: false})
          this.setState({hershey: false})
          this.setState({hormel: false})
          this.setState({hyde: false})
          this.setState({john: false})
          this.setState({juisy: false})
          this.setState({keri: false})
          this.setState({kisses: false})
          this.setState({listerine: false})
          this.setState({lubriderm: false})        
          this.setState({lysol: false})
          this.setState({mm: false})
          this.setState({madonna: false})
          this.setState({maruchan: false})
          this.setState({mcormick: false})
          this.setState({milky: false})
          this.setState({nestle: false})
          this.setState({nivea: false})
          this.setState({nodor: false})
          this.setState({orbit: false})
          this.setState({pedigree: false})
          this.setState({ponds: false})
          this.setState({pringles: false})
          this.setState({rexona: false})
          this.setState({sangre: false})
          this.setState({sedal: false})
          this.setState({skittles: false})
          this.setState({snickers: false})
          this.setState({splenda: false})
          this.setState({star: false})
          this.setState({stayfree: false})
          this.setState({summer: false})
          this.setState({treseme: false})
          this.setState({twix: false})
          this.setState({tylenol: false})
          this.setState({unilever: false})
          this.setState({vitacreme: false})
          this.setState({whiskas: false})
          this.setState({wrigley: false})
          this.setState({freegells:false})
          this.setState({french:false})
          this.setState({frontera:false})
          this.setState({harpic:false})
          this.setState({jalisco:!this.state.jalisco})
          this.setState({jellico:false})
          this.setState({chula :false})
          this.setState({lactacyd:false})
          this.setState({marques:false})
          this.setState({nerds :false})
          this.setState({niveamen:false})
          this.setState({ogx  :false})
          this.setState({oxy  :false})
          this.setState({redbul:false})
          this.setState({robert:false})
          this.setState({roy :false})
          this.setState({sweeta:false})
          this.setState({tabcin:false})
          this.setState({toñac :false})
          this.setState({toñal :false})
          this.setState({trio :false})
          this.setState({trojan:false})
          this.setState({vanish:false})
          this.setState({venadito:false})
          this.setState({victor:false})
          this.setState({vigui :false})
          this.setState({winterfresh:false})
          this.setState({woodbridge:false})
        }

  //jellico
  changeColor58(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
    this.setState({freegells:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:!this.state.jellico})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
  }

   //chula
   changeColor59(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
    this.setState({freegells:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :!this.state.chula})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
  }
     //lactacyd
     changeColor60(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
      this.setState({freegells:false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:!this.state.lactacyd})
      this.setState({marques:false})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
    }

     //marques
     changeColor61(){
      this.setState({alka: false})
      this.setState({axe: false})
      this.setState({carefree: false})
      this.setState({casillero: false})
      this.setState({clean: false})
      this.setState({colombina: false})
      this.setState({concha: false})
      this.setState({derma: false})
      this.setState({desitin: false})
      this.setState({dove: false})
      this.setState({dvant: false})
      this.setState({jimador: false})
      this.setState({evenflo: false})
      this.setState({ferrero: false})
      this.setState({glad: false})
      this.setState({herradura: false})
      this.setState({hershey: false})
      this.setState({hormel: false})
      this.setState({hyde: false})
      this.setState({john: false})
      this.setState({juisy: false})
      this.setState({keri: false})
      this.setState({kisses: false})
      this.setState({listerine: false})
      this.setState({lubriderm: false})        
      this.setState({lysol: false})
      this.setState({mm: false})
      this.setState({madonna: false})
      this.setState({maruchan: false})
      this.setState({mcormick: false})
      this.setState({milky: false})
      this.setState({nestle: false})
      this.setState({nivea: false})
      this.setState({nodor: false})
      this.setState({orbit: false})
      this.setState({pedigree: false})
      this.setState({ponds: false})
      this.setState({pringles: false})
      this.setState({rexona: false})
      this.setState({sangre: false})
      this.setState({sedal: false})
      this.setState({skittles: false})
      this.setState({snickers: false})
      this.setState({splenda: false})
      this.setState({star: false})
      this.setState({stayfree: false})
      this.setState({summer: false})
      this.setState({treseme: false})
      this.setState({twix: false})
      this.setState({tylenol: false})
      this.setState({unilever: false})
      this.setState({vitacreme: false})
      this.setState({whiskas: false})
      this.setState({wrigley: false})
      this.setState({freegells:false})
      this.setState({french:false})
      this.setState({frontera:false})
      this.setState({harpic:false})
      this.setState({jalisco:false})
      this.setState({jellico:false})
      this.setState({chula :false})
      this.setState({lactacyd:false})
      this.setState({marques:!this.state.marques})
      this.setState({nerds :false})
      this.setState({niveamen:false})
      this.setState({ogx  :false})
      this.setState({oxy  :false})
      this.setState({redbul:false})
      this.setState({robert:false})
      this.setState({roy :false})
      this.setState({sweeta:false})
      this.setState({tabcin:false})
      this.setState({toñac :false})
      this.setState({toñal :false})
      this.setState({trio :false})
      this.setState({trojan:false})
      this.setState({vanish:false})
      this.setState({venadito:false})
      this.setState({victor:false})
      this.setState({vigui :false})
      this.setState({winterfresh:false})
      this.setState({woodbridge:false})
    }    

  //nerds
  changeColor62(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
    this.setState({freegells:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :!this.state.nerds})
    this.setState({niveamen:false})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
  }        

  //niveamen
  changeColor63(){
    this.setState({alka: false})
    this.setState({axe: false})
    this.setState({carefree: false})
    this.setState({casillero: false})
    this.setState({clean: false})
    this.setState({colombina: false})
    this.setState({concha: false})
    this.setState({derma: false})
    this.setState({desitin: false})
    this.setState({dove: false})
    this.setState({dvant: false})
    this.setState({jimador: false})
    this.setState({evenflo: false})
    this.setState({ferrero: false})
    this.setState({glad: false})
    this.setState({herradura: false})
    this.setState({hershey: false})
    this.setState({hormel: false})
    this.setState({hyde: false})
    this.setState({john: false})
    this.setState({juisy: false})
    this.setState({keri: false})
    this.setState({kisses: false})
    this.setState({listerine: false})
    this.setState({lubriderm: false})        
    this.setState({lysol: false})
    this.setState({mm: false})
    this.setState({madonna: false})
    this.setState({maruchan: false})
    this.setState({mcormick: false})
    this.setState({milky: false})
    this.setState({nestle: false})
    this.setState({nivea: false})
    this.setState({nodor: false})
    this.setState({orbit: false})
    this.setState({pedigree: false})
    this.setState({ponds: false})
    this.setState({pringles: false})
    this.setState({rexona: false})
    this.setState({sangre: false})
    this.setState({sedal: false})
    this.setState({skittles: false})
    this.setState({snickers: false})
    this.setState({splenda: false})
    this.setState({star: false})
    this.setState({stayfree: false})
    this.setState({summer: false})
    this.setState({treseme: false})
    this.setState({twix: false})
    this.setState({tylenol: false})
    this.setState({unilever: false})
    this.setState({vitacreme: false})
    this.setState({whiskas: false})
    this.setState({wrigley: false})
    this.setState({freegells:false})
    this.setState({french:false})
    this.setState({frontera:false})
    this.setState({harpic:false})
    this.setState({jalisco:false})
    this.setState({jellico:false})
    this.setState({chula :false})
    this.setState({lactacyd:false})
    this.setState({marques:false})
    this.setState({nerds :false})
    this.setState({niveamen:!this.state.niveamen})
    this.setState({ogx  :false})
    this.setState({oxy  :false})
    this.setState({redbul:false})
    this.setState({robert:false})
    this.setState({roy :false})
    this.setState({sweeta:false})
    this.setState({tabcin:false})
    this.setState({toñac :false})
    this.setState({toñal :false})
    this.setState({trio :false})
    this.setState({trojan:false})
    this.setState({vanish:false})
    this.setState({venadito:false})
    this.setState({victor:false})
    this.setState({vigui :false})
    this.setState({winterfresh:false})
    this.setState({woodbridge:false})
  }        

 //ogx
 changeColor64(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :!this.state.ogx})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        


//oxy
changeColor65(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :!this.state.oxy})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//redbul
changeColor66(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:!this.state.redbul})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//robert
changeColor67(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:!this.state.robert})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//roy
changeColor68(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :!this.state.roy})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//sweetarts
changeColor69(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:!this.state.sweeta})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//tabcin
changeColor70(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:!this.state.tabcin})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
}        

//toñac
changeColor71(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :!this.state.toñac})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//toñal
changeColor72(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :!this.state.toñal})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//trio
changeColor73(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :!this.state.trio})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//trojan
changeColor74(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:!this.state.trojan})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//vanish
changeColor75(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:!this.state.vanish})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 


//venadito
changeColor76(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:!this.state.venadito})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 


//victoria
changeColor77(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:!this.state.victor})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//vigui
changeColor78(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :!this.state.vigui})
  this.setState({winterfresh:false})
  this.setState({woodbridge:false})
} 

//winterfresh
changeColor79(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:!this.state.winterfresh})
  this.setState({woodbridge:false})
} 


//winterfresh
changeColor80(){
  this.setState({alka: false})
  this.setState({axe: false})
  this.setState({carefree: false})
  this.setState({casillero: false})
  this.setState({clean: false})
  this.setState({colombina: false})
  this.setState({concha: false})
  this.setState({derma: false})
  this.setState({desitin: false})
  this.setState({dove: false})
  this.setState({dvant: false})
  this.setState({jimador: false})
  this.setState({evenflo: false})
  this.setState({ferrero: false})
  this.setState({glad: false})
  this.setState({herradura: false})
  this.setState({hershey: false})
  this.setState({hormel: false})
  this.setState({hyde: false})
  this.setState({john: false})
  this.setState({juisy: false})
  this.setState({keri: false})
  this.setState({kisses: false})
  this.setState({listerine: false})
  this.setState({lubriderm: false})        
  this.setState({lysol: false})
  this.setState({mm: false})
  this.setState({madonna: false})
  this.setState({maruchan: false})
  this.setState({mcormick: false})
  this.setState({milky: false})
  this.setState({nestle: false})
  this.setState({nivea: false})
  this.setState({nodor: false})
  this.setState({orbit: false})
  this.setState({pedigree: false})
  this.setState({ponds: false})
  this.setState({pringles: false})
  this.setState({rexona: false})
  this.setState({sangre: false})
  this.setState({sedal: false})
  this.setState({skittles: false})
  this.setState({snickers: false})
  this.setState({splenda: false})
  this.setState({star: false})
  this.setState({stayfree: false})
  this.setState({summer: false})
  this.setState({treseme: false})
  this.setState({twix: false})
  this.setState({tylenol: false})
  this.setState({unilever: false})
  this.setState({vitacreme: false})
  this.setState({whiskas: false})
  this.setState({wrigley: false})
  this.setState({freegells:false})
  this.setState({french:false})
  this.setState({frontera:false})
  this.setState({harpic:false})
  this.setState({jalisco:false})
  this.setState({jellico:false})
  this.setState({chula :false})
  this.setState({lactacyd:false})
  this.setState({marques:false})
  this.setState({nerds :false})
  this.setState({niveamen:false})
  this.setState({ogx  :false})
  this.setState({oxy  :false})
  this.setState({redbul:false})
  this.setState({robert:false})
  this.setState({roy :false})
  this.setState({sweeta:false})
  this.setState({tabcin:false})
  this.setState({toñac :false})
  this.setState({toñal :false})
  this.setState({trio :false})
  this.setState({trojan:false})
  this.setState({vanish:false})
  this.setState({venadito:false})
  this.setState({victor:false})
  this.setState({vigui :false})
  this.setState({winterfresh:false})
  this.setState({woodbridge:!this.state.woodbridge})
} 

    render(){
        let btn_class = this.state.alka ? "pointer" : "pointer";
        let btn_class2 = this.state.carefree ? "pointer" : "pointer";
        let btn_class3 = this.state.casillero ? "pointer" : "pointer";
        let btn_class4 = this.state.clean ? "pointer" : "pointer";
        let btn_class5 = this.state.colombina ? "pointer" : "pointer";
        let btn_class6 = this.state.concha ? "pointer" : "pointer";
        let btn_class7 = this.state.derma ? "pointer" : "pointer";
        let btn_class8 = this.state.desitin ? "pointer" : "pointer";
        let btn_class9 = this.state.dove ? "pointer" : "pointer";
        let btn_class10 = this.state.dvant ? "pointer" : "pointer";
        let btn_class11 = this.state.jimador ? "pointer" : "pointer";
        let btn_class12 = this.state.evenflo ? "pointer" : "pointer";
        let btn_class13 = this.state.ferrero ? "pointer" : "pointer";
        let btn_class14 = this.state.glad ? "pointer" : "pointer";
        let btn_class15 = this.state.herradura ? "pointer" : "pointer";
        let btn_class16 = this.state.hershey ? "pointer" : "pointer";
        let btn_class17 = this.state.hormel ? "pointer" : "pointer";
        let btn_class18 = this.state.hyde ? "pointer" : "pointer";
        let btn_class19 = this.state.john ? "pointer" : "pointer";
        let btn_class20 = this.state.juisy ? "pointer" : "pointer";
        let btn_class21 = this.state.kisses ? "pointer" : "pointer";
        let btn_class22 = this.state.listerine ? "pointer" : "pointer";
        let btn_class23 = this.state.lubriderm ? "pointer" : "pointer";
        let btn_class24 = this.state.lysol ? "pointer" : "pointer";
        let btn_class25 = this.state.mm ? "pointer" : "pointer";
        let btn_class26 = this.state.madonna ? "pointer" : "pointer";
        let btn_class27 = this.state.maruchan ? "pointer" : "pointer";
        let btn_class28 = this.state.mcormick ? "pointer" : "pointer";
        let btn_class29 = this.state.milky ? "pointer" : "pointer";
        let btn_class30 = this.state.nestle ? "pointer" : "pointer";
        let btn_class31 = this.state.nivea ? "pointer" : "pointer";
        let btn_class32 = this.state.nodor ? "pointer" : "pointer";
        let btn_class33 = this.state.orbit ? "pointer" : "pointer";
        let btn_class34 = this.state.pedigree ? "pointer" : "pointer";
        let btn_class35 = this.state.ponds ? "pointer" : "pointer";
        let btn_class36 = this.state.pringles ? "pointer" : "pointer";
        let btn_class37 = this.state.rexona ? "pointer" : "pointer";
        let btn_class38 = this.state.sangre ? "pointer" : "pointer";
        let btn_class39 = this.state.sedal ? "pointer" : "pointer";
        let btn_class40 = this.state.skittles ? "pointer" : "pointer";
        let btn_class41 = this.state.snickers ? "pointer" : "pointer";
        let btn_class42 = this.state.splenda ? "pointer" : "pointer";
        let btn_class43 = this.state.star ? "pointer" : "pointer";
        let btn_class44 = this.state.stayfree ? "pointer" : "pointer";
        let btn_class45 = this.state.summer ? "pointer" : "pointer";
        let btn_class46 = this.state.treseme ? "pointer" : "pointer";
        let btn_class47 = this.state.twix ? "pointer" : "pointer";
        let btn_class48 = this.state.tylenol ? "pointer" : "pointer";
        let btn_class49 = this.state.unilever ? "pointer" : "pointer";
        let btn_class50 = this.state.vitacreme ? "pointer" : "pointer";
        let btn_class51 = this.state.whiskas ? "pointer" : "pointer";
        let btn_class52 = this.state.wrigley ? "pointer" : "pointer";
        let btn_class53 = this.state.freegells ? "pointer" : "pointer";
        let btn_class54 = this.state.french ? "pointer" : "pointer";
        let btn_class55 = this.state.frontera ? "pointer" : "pointer";
        let btn_class56 = this.state.harpic ? "pointer" : "pointer";
        let btn_class57 = this.state.jalisco ? "pointer" : "pointer";
        let btn_class58 = this.state.jellico ? "pointer" : "pointer";
        let btn_class59 = this.state.chula ? "pointer" : "pointer";
        let btn_class60 = this.state.lactacyd ? "pointer" : "pointer";
        let btn_class61 = this.state.marques ? "pointer" : "pointer";
        let btn_class62 = this.state.nerds ? "pointer" : "pointer";
        let btn_class63 = this.state.niveamen ? "pointer" : "pointer";
        let btn_class64 = this.state.ogx ? "pointer" : "pointer";
        let btn_class65 = this.state.oxy ? "pointer" : "pointer";
        let btn_class66 = this.state.redbull ? "pointer" : "pointer";
        let btn_class67 = this.state.robert ? "pointer" : "pointer";
        let btn_class68 = this.state.roy ? "pointer" : "pointer";
        let btn_class69 = this.state.sweeta ? "pointer" : "pointer";
        let btn_class70 = this.state.tabcin ? "pointer" : "pointer";
        let btn_class71 = this.state.toñac ? "pointer" : "pointer";
        let btn_class72 = this.state.toñal ? "pointer" : "pointer";
        let btn_class73 = this.state.trio ? "pointer" : "pointer";
        let btn_class74 = this.state.trojan ? "pointer" : "pointer";
        let btn_class75 = this.state.vanish ? "pointer" : "pointer";
        let btn_class76 = this.state.venadito ? "pointer" : "pointer";
        let btn_class77 = this.state.victor ? "pointer" : "pointer";
        let btn_class78 = this.state.vigui ? "pointer" : "pointer";
        let btn_class79 = this.state.winterfresh ? "pointer" : "pointer";
        let btn_class80 = this.state.woodbridge ? "pointer" : "pointer";
        

        return (
<section className="pt-5 pb-5">
  <div className="container">
    <div className="row">
        <div className="col-12 border-bottom">
            <h3 className="mb-3 text-primary">Socios comerciales consumo </h3>
        </div>

        <div className="col-12">
            {/* <div id="carouselExampleIndicators3" className="carousel slide w-75 marginleft" data-ride="carousel">

                <div className="carousel-inner"> */}
                    {/* <div className="carousel-item active"  data-interval="5000"> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                              <div className={btn_class} onClick={this.changeColor.bind(this)} data-toggle="modal" data-target="#airwick">
                                <img src={airwick}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class2} onClick={this.changeColor2.bind(this)} data-toggle="modal" data-target="#aleve">
                                <img src={Aleve}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class3} onClick={this.changeColor3.bind(this)} data-toggle="modal" data-target="#alka">
                                <img src={alka}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class4} onClick={this.changeColor4.bind(this)} data-toggle="modal" data-target="#ferrero">
                                <img src={BIC}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                              <div className={btn_class5} onClick={this.changeColor5.bind(this)} data-toggle="modal" data-target="#hormel">
                                <img src={Bug}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class6} onClick={this.changeColor6.bind(this)} data-toggle="modal" data-target="#maruchan">
                                <img src={Cardio}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            {/* <div className="col-md-3 mb-3">
                              <div className={btn_class7} onClick={this.changeColor7.bind(this)} data-toggle="modal" data-target="#carefree">
                                <img src={carefree}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div> */}
                            <div className="col-md-3 mb-3">
                              <div className={btn_class8} onClick={this.changeColor8.bind(this)} data-toggle="modal" data-target="#casillero">
                                <img src={casillero}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div>


                    <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                              <div className={btn_class9} onClick={this.changeColor9.bind(this)} data-toggle="modal" data-target="#ponds">
                                <img src={charisma}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#cleananclear">
                                <img src={cleananclear}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class10} onClick={this.changeColor10.bind(this)} data-toggle="modal" data-target="#rexona">
                                <img src={clorox}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class11} onClick={this.changeColor11.bind(this)} data-toggle="modal" data-target="#sedal">
                                <img src={clos}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div> */}


                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                        <div className="col-md-3 mb-3">
                              <div className={btn_class12} onClick={this.changeColor12.bind(this)} data-toggle="modal" data-target="#colombina">
                                <img src={COLOMBINA}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class12} onClick={this.changeColor12.bind(this)} data-toggle="modal" data-target="#concha">
                                <img src={concha}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                            <div className={btn_class13} onClick={this.changeColor13.bind(this)} data-toggle="modal" data-target="#treseme">
                              <img src={coronas}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                            <div className="col-md-3 mb-3">
                              <div className={btn_class14} onClick={this.changeColor14.bind(this)} data-toggle="modal" data-target="#derma">
                                <img src={DERMANATURAL}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          
                            <div className="col-md-3 mb-3">
                              <div className={btn_class15} onClick={this.changeColor15.bind(this)} data-toggle="modal" data-target="#desitin">
                                <img src={desitin}  className="p-2 w-100 center" alt="..." />
                              </div>
                            </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class16} onClick={this.changeColor16.bind(this)} data-toggle="modal" data-target="#unilever">
                              <img src={donmelchor}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                          <div className={btn_class17} onClick={this.changeColor17.bind(this)}  data-toggle="modal" data-target="#wrigley">
                            <img src={doublemint}  className="p-2 w-100 center" alt="..." />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className={btn_class17} onClick={this.changeColor17.bind(this)}  data-toggle="modal" data-target="#dov">
                            <img src={dove}  className="p-2 w-100 center" alt="..." />
                          </div>
                        </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                      <div className="row">
                        
                        <div className="col-md-3 mb-3">
                          <div className={btn_class18} onClick={this.changeColor18.bind(this)} data-toggle="modal" data-target="#dvant">
                            <img src={DVANT}  className="p-2 w-100 center" alt="..." />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className={btn_class19} onClick={this.changeColor19.bind(this)}  data-toggle="modal" data-target="#jimador">
                              <img src={JIMADOR}  className="p-2 w-100 center" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className={btn_class20} onClick={this.changeColor20.bind(this)} data-toggle="modal" data-target="#evenflo">
                              <img src={evenflo}  className="p-2 w-100 center" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className={btn_class20} onClick={this.changeColor20.bind(this)} data-toggle="modal" data-target="#ferro">
                              <img src={Ferro}  className="p-2 w-100 center" alt="..." />
                            </div>
                        </div>
                      </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                      <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class21} onClick={this.changeColor21.bind(this)} data-toggle="modal" data-target="#freegells">
                              <img src={freegells}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class22} onClick={this.changeColor22.bind(this)} data-toggle="modal" data-target="#french">
                              <img src={french}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class23} onClick={this.changeColor23.bind(this)} data-toggle="modal" data-target="#frontera">
                              <img src={frontera}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class24} onClick={this.changeColor24.bind(this)}  data-toggle="modal" data-target="#glad">
                              <img src={glad}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}


                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class25} onClick={this.changeColor25.bind(this)} data-toggle="modal" data-target="#harpic">
                              <img src={harpic}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class26} onClick={this.changeColor26.bind(this)}  data-toggle="modal" data-target="#herradura">
                              <img src={HERRADURA}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class27} onClick={this.changeColor27.bind(this)} data-toggle="modal" data-target="#hersheys">
                              <img src={hersheys}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class28} onClick={this.changeColor28.bind(this)} data-toggle="modal" data-target="#hor">
                              <img src={Hor}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class28} onClick={this.changeColor28.bind(this)} data-toggle="modal" data-target="#hyde">
                              <img src={HYDE}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          {/* <div className="col-md-3 mb-3">
                            <div className={btn_class29} onClick={this.changeColor29.bind(this)} data-toggle="modal" data-target="#jalisco">
                              <img src={jalisco}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <div className={btn_class30} onClick={this.changeColor30.bind(this)} data-toggle="modal" data-target="#jellico">
                              <img src={jellico}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class31} onClick={this.changeColor31.bind(this)}  data-toggle="modal" data-target="#johnson">
                              <img src={johnson}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class32} onClick={this.changeColor32.bind(this)} data-toggle="modal" data-target="#juicy">
                              <img src={JUICY}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>

                        </div>
                    {/* </div> */}


                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          
                          <div className="col-md-3 mb-3">
                            <div className={btn_class33} onClick={this.changeColor33.bind(this)} data-toggle="modal" data-target="#kisses">
                              <img src={KISSES}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class34} onClick={this.changeColor34.bind(this)}  data-toggle="modal" data-target="#chula">
                              <img src={chula}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class35} onClick={this.changeColor35.bind(this)} data-toggle="modal" data-target="#lactacyd">
                              <img src={lactacyd}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class36} onClick={this.changeColor36.bind(this)} data-toggle="modal" data-target="#listerine">
                              <img src={listerine}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          <div className="col-md-3 mb-3">
                            <div className={btn_class37} onClick={this.changeColor37.bind(this)} data-toggle="modal" data-target="#lubriderm">
                              <img src={lubriderm}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class38} onClick={this.changeColor38.bind(this)}  data-toggle="modal" data-target="#lysol">
                              <img src={LYSOL}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class39} onClick={this.changeColor39.bind(this)}  data-toggle="modal" data-target="#mm">
                              <img src={MM}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class40} onClick={this.changeColor40.bind(this)} data-toggle="modal" data-target="#madonna">
                              <img src={MADONNA}  className="p-2 w-100 center" alt="..." />
                            </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class41} onClick={this.changeColor41.bind(this)}  data-toggle="modal" data-target="#marques">
                              <img src={marques}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class41} onClick={this.changeColor41.bind(this)}  data-toggle="modal" data-target="#mar">
                              <img src={Mar}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class42} onClick={this.changeColor42.bind(this)} data-toggle="modal" data-target="#mcormick">
                              <img src={MCCORMICK}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class43} onClick={this.changeColor43.bind(this)} data-toggle="modal" data-target="#milky">
                              <img src={MILKY}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                
                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          <div className="col-md-3 mb-3">
                            <div className={btn_class44} onClick={this.changeColor44.bind(this)} data-toggle="modal" data-target="#nerds">
                              <img src={nerds}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class45} onClick={this.changeColor45.bind(this)}  data-toggle="modal" data-target="#nestle">
                              <img src={nestle}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class46} onClick={this.changeColor46.bind(this)} data-toggle="modal" data-target="#nivea">
                              <img src={nivea}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class47} onClick={this.changeColor47.bind(this)} data-toggle="modal" data-target="#niveamen">
                              <img src={niveamen}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          <div className="col-md-3 mb-3">
                            <div className={btn_class48} onClick={this.changeColor48.bind(this)} data-toggle="modal" data-target="#nodor">
                              <img src={NODOR}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#ogx">
                              <img src={OGX}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#orbit">
                              <img src={ORBIT}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#oxy">
                              <img src={oxy}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}


                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#pedigree">
                              <img src={PEDIGREE}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#pon">
                              <img src={pon}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#pringles">
                              <img src={PRINGLES}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#redbull">
                              <img src={redbull}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">


                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#rex">
                              <img src={rex}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#robert">
                              <img src={robert}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#roy">
                              <img src={roy}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#sangre">
                              <img src={SANGRE}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                
                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#sed">
                              <img src={sed}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#skittles">
                              <img src={skittles}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#snickers">
                              <img src={SNICKERS}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#splenda">
                              <img src={splenda}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}

                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#star">
                              <img src={STARBURST}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#stayfree">
                              <img src={STAYFREE}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#summer">
                              <img src={SUMMER}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#sweetarts">
                              <img src={sweetarts}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}


                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">


                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#tabcin">
                              <img src={tabcin}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#toñac">
                              <img src={toñac}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#toñal">
                              <img src={toñal}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#tres">
                              <img src={tres}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                
                    {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                        
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#trio">
                              <img src={trio}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#trojan">
                              <img src={trojan}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#twix">
                              <img src={TWIX}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#tylenol">
                              <img src={TYLENOL}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                
 {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                          

                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#uni">
                              <img src={uni}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#vanish">
                              <img src={vanish}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#venadito">
                              <img src={venadito}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#victoria">
                              <img src={victoria}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                 {/*    </div>
                 <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">


                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#vigui">
                              <img src={vigui}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class52} onClick={this.changeColor52.bind(this)} data-toggle="modal" data-target="#vitacreme">
                              <img src={VITACREME}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#whiskas">
                              <img src={WHISKAS}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                
                 {/* <div className="carousel-item"  data-interval="5000"> */}
                        <div className="row">
                    
                          <div className="col-md-3 mb-3">
                            <div className={btn_class50} onClick={this.changeColor50.bind(this)} data-toggle="modal" data-target="#winterfresh">
                              <img src={Winterfresh}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class51} onClick={this.changeColor51.bind(this)} data-toggle="modal" data-target="#woodbridge">
                              <img src={WoodBridge}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className={btn_class49} onClick={this.changeColor49.bind(this)}  data-toggle="modal" data-target="#wrig">
                              <img src={wrig}  className="p-2 w-100 center" alt="..." />
                            </div>
                          </div>
                        </div>
                    {/* </div> */}
                

                {/* </div>
            </div> */}

            

            {/* <a className="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
              <i className="fas fa-angle-left text-primary fa-2x"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
              <i className="fas fa-angle-right text-primary fa-2x"></i>
              <span className="sr-only">Next</span>
            </a> */}
        </div>
    </div>
</div>

      
        {/* <!-- Modal 1--> */}
<div className="modal fade" id="alka" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoAlka}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 2--> */}
{/* <div className="modal fade" id="carefree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor2.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoCarefree}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div> */}


{/* <!-- Modal 3--> */}
<div className="modal fade" id="casillero" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor3.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoCasillero}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 4--> */}
<div className="modal fade" id="cleanC" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor4.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoClean}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="colombina" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoColombina}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 6--> */}
<div className="modal fade" id="airwick" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor6.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoConcha}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 7--> */}
<div className="modal fade" id="derma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor7.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDerma}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 8--> */}
<div className="modal fade" id="desitin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor8.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDesitin}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 9--> */}
<div className="modal fade" id="aleve" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor9.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDove}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 10--> */}
<div className="modal fade" id="dvant" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor10.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDvant}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 11--> */}
<div className="modal fade" id="jimador" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor11.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoJimador}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 12--> */}
<div className="modal fade" id="evenflo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor12.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoEvenflo}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 13--> */}
<div className="modal fade" id="ferrero" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor13.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoBic}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 14--> */}
<div className="modal fade" id="glad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor14.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoGlad}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 15--> */}
<div className="modal fade" id="herradura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor15.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHerradura}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 16--> */}
<div className="modal fade" id="hersheys" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor16.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHersheys}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


  {/* <!-- Modal 17--> */}
<div className="modal fade" id="hormel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor17.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHormel}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 18--> */}
<div className="modal fade" id="hyde" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor18.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHyde}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 19--> */}
 <div className="modal fade" id="johnson" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor19.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoJohn}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 20--> */}
 <div className="modal fade" id="juicy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor20.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoJuicy}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 21--> */}
<div className="modal fade" id="kisses" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor21.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoKisses}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 22--> */}
 <div className="modal fade" id="listerine" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor22.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoListerine}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 23--> */}
 <div className="modal fade" id="lubriderm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor23.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoLubriderm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 24--> */}
<div className="modal fade" id="lysol" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor24.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoLysol}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 25--> */}
<div className="modal fade" id="mm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor25.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMm}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 26--> */}
<div className="modal fade" id="madonna" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor26.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMadonna}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 27--> */}
<div className="modal fade" id="maruchan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor27.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMaruchan}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 28--> */}
<div className="modal fade" id="mcormick" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor28.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMcormick}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 29--> */}
<div className="modal fade" id="milky" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor29.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMilky}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 30--> */}
 <div className="modal fade" id="nestle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor30.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNestle}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 31--> */}
 <div className="modal fade" id="nivea" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor31.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNivea}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 32--> */}
<div className="modal fade" id="nodor" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor32.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNodor}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 33--> */}
<div className="modal fade" id="orbit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor33.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoOrbit}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 34--> */}
<div className="modal fade" id="pedigree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor34.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPedigree}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 35--> */}
 <div className="modal fade" id="ponds" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor35.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPonds}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 36--> */}
<div className="modal fade" id="pringles" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor36.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPringles}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 37--> */}
 <div className="modal fade" id="rexona" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor37.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRexona}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 38--> */}
<div className="modal fade" id="sangre" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor38.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSangre}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 39--> */}
 <div className="modal fade" id="sedal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor39.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logosedal}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

 {/* <!-- Modal 40--> */}
 <div className="modal fade" id="skittles" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor40.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSkittles}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 41--> */}
<div className="modal fade" id="snickers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor41.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSnickers}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 42--> */}
 <div className="modal fade" id="splenda" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor42.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSplenda}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 43--> */}
<div className="modal fade" id="star" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor43.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoStar}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 44--> */}
<div className="modal fade" id="stayfree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor44.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoStayfree}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 45--> */}
<div className="modal fade" id="summer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor45.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSummer}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 46--> */}
 <div className="modal fade" id="treseme" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor46.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTresemme}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 47--> */}
<div className="modal fade" id="twix" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor47.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTwix}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 48--> */}
 <div className="modal fade" id="tylenol" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor48.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTylenol}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


 {/* <!-- Modal 49--> */}
 <div className="modal fade" id="unilever" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor49.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoUnilever}  className="img-fluid"  alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 50--> */}
<div className="modal fade" id="vitacreme" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor50.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVitacreme}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 51--> */}
<div className="modal fade" id="whiskas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor51.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoWhiskas}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 52--> */}
<div className="modal fade" id="wrigley" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor52.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoWrigley}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 53--> */}
<div className="modal fade" id="freegells" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor53.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFreegells}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 54--> */}
<div className="modal fade" id="french" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor54.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFrench}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 55--> */}
<div className="modal fade" id="frontera" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor55.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFrontera}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 56--> */}
<div className="modal fade" id="harpic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor56.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHarpic}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 57--> */}
<div className="modal fade" id="jalisco" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor57.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoJalisco}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 58--> */}
<div className="modal fade" id="jellico" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor58.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoJellico}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 59--> */}
<div className="modal fade" id="chula" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor59.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoChula}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 60--> */}
<div className="modal fade" id="lactacyd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor60.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoLactacyd}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 61--> */}
<div className="modal fade" id="marques" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor61.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMarques}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 62--> */}
<div className="modal fade" id="nerds" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor62.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNerds}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 63--> */}
<div className="modal fade" id="niveamen" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor63.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoNiveaMen}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 64--> */}
<div className="modal fade" id="ogx" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor64.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoOGX}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 65--> */}
<div className="modal fade" id="oxy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor65.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoOxy}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 66--> */}
<div className="modal fade" id="redbull" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor66.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRedbull}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 67--> */}
<div className="modal fade" id="robert" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor67.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRobert}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 68--> */}
<div className="modal fade" id="roy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor68.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRoy}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 69--> */}
<div className="modal fade" id="sweetarts" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor69.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSweetarts}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 70--> */}
<div className="modal fade" id="tabcin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor70.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTabcin}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 71--> */}
<div className="modal fade" id="toñac" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor71.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTonac}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 72--> */}
<div className="modal fade" id="toñal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor72.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTonal}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 73--> */}
<div className="modal fade" id="trio" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor73.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTrio}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 74--> */}
<div className="modal fade" id="trojan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor74.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTrojan}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 75--> */}
<div className="modal fade" id="vanish" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor75.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVanish}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 76--> */}
<div className="modal fade" id="venadito" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor76.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVenadito}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 77--> */}
<div className="modal fade" id="victoria" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor77.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVictoria}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 78--> */}
<div className="modal fade" id="vigui" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor78.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoVigui}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 79--> */}
<div className="modal fade" id="winterfresh" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor79.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoWinterfresh}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 80--> */}
<div className="modal fade" id="woodbridge" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor80.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoWoodbridge}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="cleananclear" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logocleananclear}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="dov" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoDov}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="ferro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoFerro}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal 5--> */}
<div className="modal fade" id="hor" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoHor}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="mar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoMar}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="pon" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoPon}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="sed" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoSed}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="tres" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoTres}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- Modal 5--> */}
<div className="modal fade" id="concha" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoCon}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 5--> */}
<div className="modal fade" id="rex" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoRex}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 5--> */}
<div className="modal fade" id="uni" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoUni}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- Modal 5--> */}
<div className="modal fade" id="wrig" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content2">
      <div className="modal-body">
      <button type="button" className="close2 border-0" onClick={this.changeColor5.bind(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i className="far fa-times-circle text-primary"></i></span>
        </button>
       <div className="row">
           <div className="col-md-12">
           <img src={logoWrig}  className="img-fluid" alt="..." />
           </div>
       </div>
      </div>
    </div>
  </div>
</div>



</section>
 )
}
}export default Consumo;