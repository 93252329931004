/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import Historia from '../../img/Nuestra historia.jpg';

const farinterhisto = () => (
  <div >
    <img src={Historia} className="d-block w-100 mt-3"/>
  </div>
);
export default farinterhisto;